import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AssistHostComponent } from './assist-host.component';
import { AssistModule } from 'carina-workflow-assist';

import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    AssistHostComponent
  ],
  exports: [
    AssistHostComponent,
  ],
  imports: [ 
    AssistModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    MaterialModule
  ],
  // entryComponents: [
  //   MatButtonToggle, MatButtonToggleGroup
  // ],
  // bootstrap: [MatButtonToggle, MatButtonToggleGroup]
})
export class AssistHostModule {
  constructor() {
  }
}