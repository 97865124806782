import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";
import { ValueBoolean } from "./value-boolean";
import { ValueNumber } from "./value-number";
import { ValueString } from "./value-string";
import { ValueReference } from "./value-reference";
import { ValueObject } from "./value-object";
import { EvalNodeType } from "../types/eval-type";
import { EnumValueState } from "../enums/enum-value-state";
import { EvalNode } from "./eval-node";
import { AssistInjector } from '../services/assist-injector';
import { AssistStateService } from "../services/assist-state.service";
import { EnumLogType } from "../enums/enum-log-type";
import { IErrorData } from "../interfaces/ierror-data";

export class ValueFactory {

  private constructor() { }

  static create(config: IDataValue): ValueBase {

    let result = null;

    switch (config.type) {
      case  EnumValueType.Value_boolean: 
        result = new ValueBoolean(config);
      break;
      case  EnumValueType.Value_number: 
        result = new ValueNumber(config);
      break;
      case  EnumValueType.Value_string: 
        result = new ValueString(config);
      break;
      case  EnumValueType.Value_object: 
        result = new ValueObject(config);
      break;
      case  EnumValueType.Value_unknown: 
        result = new ValueString(config);
      break;
    }

    return result;
  }

  static createFromLiteral(node : EvalNodeType) : ValueBase {

    let result : ValueBase = null;
    let nodeType = typeof node;
    let valueType : EnumValueType
    let name = "Val";

    switch (nodeType) {
      case "object":
        valueType = EnumValueType.Value_object;
        name = "obj" + name;
      break;  
      case "boolean":
        valueType = EnumValueType.Value_boolean;
        name = "bool" + name;
      break;  
      case "number":
        valueType = EnumValueType.Value_number;
        name = "num" + name;
      break;  
      case "string":
        valueType = EnumValueType.Value_string;
        name = "str" + name;
      break;  
    }

    return ValueFactory.create({type : valueType, name:name, value:node, literal:true });

  }

  static createErrorValue(valueType : EnumValueType, strError : string, evalNode : EvalNode) : ValueBase {

    let result : ValueBase = ValueFactory.create({type : valueType, name:"errVal", literal: true});

    let strErrorText : string = strError;
    if (evalNode && evalNode.dbgLine !== undefined && evalNode.dbgOffset !== undefined) {
      strErrorText = strError + " (line: " + evalNode.dbgLine + " offset: " + evalNode.dbgOffset + ")";
    }

    result.setError(strErrorText);

    console.log("Created Error: " + strErrorText);

    let assistStateService = AssistInjector.get(AssistStateService);

    assistStateService.logger(EnumLogType.log_error, result.error);

    return result;
  }

  static createErrorData(valueType : EnumValueType, errData : IErrorData, evalNode : EvalNode) : ValueBase {

    let result : ValueBase = ValueFactory.create({type : valueType, name:"errVal", literal: true});

    let strErrorText : string = "("+errData.errNum+") " + errData.errMsg;
    if (evalNode && evalNode.dbgLine !== undefined && evalNode.dbgOffset !== undefined) {
      strErrorText = strErrorText + " (line: " + evalNode.dbgLine + " offset: " + evalNode.dbgOffset + ")";
    }

    result.setError(strErrorText);
    result.setErrorData(errData);

    console.log("Created Error: " + strErrorText);

    let assistStateService = AssistInjector.get(AssistStateService);

    assistStateService.logger(EnumLogType.log_error, result.error);

    return result;
  }

  static createPendigCallResult(valueType : EnumValueType) : ValueBase {

    let valueName = "resultValue";
    let result : ValueBase = ValueFactory.create({type : valueType, name:valueName});

    result.setError("Pending Value: " +valueName);
    result.setState(EnumValueState.FieldState_Pending);

    return result;
  }

  static createPendigValue(valueType : EnumValueType, dataValue : ValueBase) : ValueBase {

    let result : ValueBase = ValueFactory.create({type : valueType, name:dataValue.name});
    let error = dataValue.error;

    if (error == "") {
      error = "Pending Value: " + dataValue.name;
    }

    result.setError(error);
    result.setState(EnumValueState.FieldState_Pending);

    return result;
  }

  static createResultValue (valueType : EnumValueType, evalNode : EvalNode, ...dataValues: ValueBase[]) : ValueBase {
    let result : ValueBase = null;

    dataValues.some(dataValue => {

      if (!dataValue.isValue) {
        if (dataValue.isUndefined) {
          if (dataValue.isReference) {
            result = ValueFactory.createErrorValue(valueType, "Undefined value: " + dataValue.name + dataValue.getPathText(), evalNode);
          } else {
            result = ValueFactory.createErrorValue(valueType, "Undefined value: " + dataValue.name, evalNode);
          }
        } else if (dataValue.isError) {
          result = ValueFactory.createErrorValue(valueType, dataValue.error, evalNode);
        } else if (dataValue.isPending) {
          result = ValueFactory.createPendigValue(valueType, dataValue);
        }  
        return true;
      } else {
        return false;
      }
    });

    if (result == null) {
      result = ValueFactory.createErrorValue(valueType, "Unexpected call to error result", evalNode);
    }

    return result;
  }

  //static createValueReference(dataValue : ValueBase, type : EnumValueType, path: string[], value : any) : ValueBase {
  static createValueReference(dataValue : ValueBase, path: string[]) : ValueBase {

    return new ValueReference(dataValue, path);

  }
}
