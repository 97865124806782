
import { EnumEventType } from '../enums/enum-event-type';
import { IStepPrompt } from '../interfaces/istep-prompt';
import { ValueBase } from './value-base';
import { EnumValueType } from '../enums/enum-value-type';
import { EvalNode } from './eval-node';
import { EvalNodeType, IEvalNodeType } from '../types/eval-type';
import { StepPrompt } from './step-prompt';
import { EnumValueSource } from '../enums/enum-value-source';
import { EnumStepType } from '../enums/enum-step-type';

export class StepPromptMulti extends StepPrompt {
 
  public entryList : EvalNodeType = null;
  public arrEntryList : any[];
  public entryText : EvalNodeType = null;
  public arrEntryText : string[];
  public entryValue : EvalNodeType = null;
  public arrEntryValue : string[];
  public entryData : EvalNodeType = null;
  public arrEntryData : any[];
  
  constructor(
    index: number,
    config: IStepPrompt,
    defaultName : string
  ) {
    super(index, config, defaultName);

    this.valueType = EnumValueType.Value_object;

    this._config = config;  

  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.entryList !== undefined) {
      evalNodes.push(this._config.entryList);
    }

    if (this._config.entryText !== undefined) {
      evalNodes.push(this._config.entryText);
    }

    if (this._config.entryValue !== undefined) {
      evalNodes.push(this._config.entryValue);
    }    

    if (this._config.entryData !== undefined) {
      evalNodes.push(this._config.entryData);
    }    

    return evalNodes.concat(super.getEvalNodes());

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.entryList !== undefined) {
      this.entryList = evalNodes.splice(0,1)[0];
    }

    if (this._config.entryText !== undefined) {
      this.entryText = evalNodes.splice(0,1)[0];
    }

    if (this._config.entryValue !== undefined) {
      this.entryValue = evalNodes.splice(0,1)[0];
    }    

    if (this._config.entryData !== undefined) {
      this.entryData = evalNodes.splice(0,1)[0];
    }    

    super.setEvalNodes(evalNodes);

  }

  async activate(): Promise<boolean> {
    let result: boolean;

    if (this.dataValue == null) {
      this.dataValue = this.scope.findDeclareValue({type:this.valueType, name: this.valueName, value:[]});

      if (this.entryList) {

        let entryValues = await this.assistEval.evaluate(this.entryList);

        this.arrEntryList = entryValues.getValue() as any[];

        if (this.entryText !== null) {

          let textValues : ValueBase;

          if (EvalNode.isEvalNode(this.entryText)) {
            let textNode : EvalNode = this.entryText as EvalNode; 

            if (textNode.source == EnumValueSource.ValueSource_procedure) {

              let evalNode = new EvalNode(this.scope, {name:"arrayMapFunc", source:EnumValueSource.ValueSource_function, type:EnumValueType.Value_object});

              evalNode.params.push(entryValues.getValue());
              evalNode.params.push(textNode);

              textValues = await this.assistEval.evaluate(evalNode);
              
              
            } else {
              
              textValues = await this.assistEval.evaluate(textNode);
              
            }  
          } else {

            textValues = await this.assistEval.evaluate(this.entryText);
          }
          this.arrEntryText = textValues.getValue() as string[];
        } else {
          this.arrEntryText = entryValues.getValue() as string[];
        }

        if (this.entryValue !== null) {

          let valueValues : ValueBase;

          if (EvalNode.isEvalNode(this.entryValue)) {
            let valueNode : EvalNode = this.entryValue as EvalNode; 

            if (valueNode.source == EnumValueSource.ValueSource_procedure) {

              let evalNode = new EvalNode(this.scope, {name:"arrayMapFunc", source:EnumValueSource.ValueSource_function, type:EnumValueType.Value_object});

              evalNode.params.push(entryValues.getValue());
              evalNode.params.push(valueNode);

              valueValues = await this.assistEval.evaluate(evalNode);
              
              
            } else {
              
              valueValues = await this.assistEval.evaluate(valueNode);
              
            }  
          } else {

            valueValues = await  this.assistEval.evaluate(this.entryValue);
          }
          this.arrEntryValue = valueValues.getValue() as string[];
        } else {
          this.arrEntryValue = this.arrEntryText;
        }

        if (this.entryData !== null) { 
          let dataValues : ValueBase;

          if (EvalNode.isEvalNode(this.entryData)) {
            let dataNode : EvalNode = this.entryData as EvalNode; 

            if (dataNode.source == EnumValueSource.ValueSource_procedure) {

              let evalNode = new EvalNode(this.scope, {name:"arrayMapFunc", source:EnumValueSource.ValueSource_function, type:EnumValueType.Value_object});

              evalNode.params.push(entryValues.getValue());
              evalNode.params.push(dataNode);

              dataValues = await this.assistEval.evaluate(evalNode);
              
              
            } else {
              
              dataValues = await this.assistEval.evaluate(dataNode);
              
            }  
          } else {

            dataValues = await  this.assistEval.evaluate(this.entryData);
          }
          this.arrEntryData = dataValues.getValue() as string[];          
        } else {
          this.arrEntryData = null;
        }  
      }  

      if (this.type == EnumStepType.Prompt_Multi || this.type == EnumStepType.Prompt_Flags) {
        this.value = [];
      }

    } else {

      if (this.dataValue.isUndefined) {
        this.value = '';
      }

      this.assistUpdateComponent();
    }

    result = await super.activate();
     
    return result;
  }

  deactivate(): boolean {
    let result = super.deactivate();

    this.stepCompleted = false;

    return result;
  }

  updateValue() {

    if (this.dataValue !== null) {
      this.dataValue.setValue(this.value);
    }
  }

  processUpdate() {

    this.assistStateService.checkSession();

    this.stepCompleted = false;
    this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });
  }

}


