import { IStepEval } from "../interfaces/istep-eval";
import { StepBase } from "./step-base";
import { EnumValueType } from "../enums/enum-value-type";
import { EnumEventType } from "../enums/enum-event-type";
import { ValueBase } from "./value-base";
import { EnumValueState } from "../enums/enum-value-state";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";

export class StepEvalDeclare extends StepBase {

  public valueName : string;
  public valueType: EnumValueType;
  public dataValue: ValueBase; 
  public initEval : EvalNodeType;

  private _config : IStepEval;

  constructor(
    index: number,
    config: IStepEval
  ) {
    super(index, config);

    this.dataValue = null;
    this.valueName = config.valueName;
    this.valueType = config.valueType;
    this.initEval = null;

    this._config = config;

  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.initValue !== undefined) {
      evalNodes.push(this._config.initValue);
    }

    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.initValue !== undefined) {
      this.initEval = evalNodes.splice(0,1)[0];
    }

    this._config = null;

  }


  async activate(): Promise<boolean> {
    let result: boolean = false;

    await super.activate();

    return this.evaluate();
  }

  private async evaluate(isCall: boolean = false) : Promise<boolean> {

    let result: boolean = false;
    let initDataValue = null;

    let eventType : EnumEventType = isCall ? EnumEventType.stepCallComplete : EnumEventType.stepComplete;
    let eventData = {index: this.index, returnDataValue: null};

    if (this.initEval !== undefined) {

      initDataValue = await this.assistEval.evaluate(this.initEval);

      if (initDataValue.isError) {
        this.processText = "Error Initialising " + this.valueName + ": " + initDataValue.error;
        result = true;
      }
    }

    this.dataValue = this.scope.findLocalValue(this.valueName);

    if (this.dataValue == null) {

      let valueConfig = {name:this.valueName, type:this.valueType};

      if (initDataValue && initDataValue.isValue) {
        valueConfig["value"] = initDataValue.getValue();
      }

      this.dataValue = this.scope.declareValue(valueConfig);
    } else {

      if (initDataValue && initDataValue.isValue) {
        this.dataValue.setValue(initDataValue.getValue());
      }      
    }

    if (this.dataValue.isError) {
      this.processText = "Error declaring " + this.valueName + ": " + this.dataValue.error; 
      result = true;
    } else {
      this.sendEvent(eventType, eventData);    
    }

    return result;

  }

  deactivate(): boolean {
    let result: boolean = false;

    result = super.deactivate();

    if (this.dataValue) {
      this.dataValue.setState(EnumValueState.FieldState_Undefined);
    }

    return result;
  }  

  async callStep() {

    await this.evaluate(true);

  }
}

