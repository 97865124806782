
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAssistConfigResponse, IAssistSessionResponse } from '../interfaces/iassist-config';
import { IMsgBoxData} from '../interfaces/imsg-box-data';
import { EnumLogType } from '../enums/enum-log-type'
import { ValueBase } from '../classes/value-base';
import { MessagePoster } from '../classes/message-poster';
import { timer } from 'rxjs';
import { IContextService } from '../interfaces/icontext-service';
import { environment } from '../environment/environment';
import { AssistConfig } from '../classes/assist-config';
import { EnumDebugLevel } from '../enums/enum-debug-level';
import { LayoutOptionType } from '../types/layout-option-type';

@Injectable({
  providedIn: 'root'
})
export class AssistStateService { 

  workflowData : any = null;
  //public serverPrefix : string;
  public serverSuffix : string;
  public isActive : boolean = false;
  public isLoading : boolean = false;
  public scriptId : number = 0;
  public scriptInstanceId : number = 0;
  public config : AssistConfig = null;

  private _systemInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly systemInProgress:Observable<boolean> = this._systemInProgress.asObservable();   

  private _stepProcessText: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public readonly stepProcessText:Observable<string> = this._stepProcessText.asObservable();  

  private _runtimeError: Subject<string> = new Subject<string>();
  public readonly onRuntimeError:Observable<string> = this._runtimeError.asObservable();  

  private _consoleLog: Subject<string> = new Subject<string>();
  public readonly onConsoleLog:Observable<string> = this._consoleLog.asObservable();  

  private _assistWait: Subject<any> = new Subject<string>();
  public readonly onAssistWait:Observable<any> = this._assistWait.asObservable(); 

  private _assistWaitResult: Subject<any> = new Subject<any>();
  public readonly onAssistWaitResult:Observable<any> = this._assistWaitResult.asObservable(); 

  private _displayMsgBox: Subject<IMsgBoxData> = new Subject<IMsgBoxData>();
  public readonly onDisplayMsgBox:Observable<IMsgBoxData> = this._displayMsgBox.asObservable(); 

  private _msgBoxResult: Subject<any> = new Subject<any>();
  public readonly onMsgBoxResult:Observable<any> = this._msgBoxResult.asObservable(); 

  private _userCancel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onUserCancel:Observable<boolean> = this._userCancel.asObservable(); 

  private _scriptRunning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onScriptRunning:Observable<boolean> = this._scriptRunning.asObservable(); 

  private _actionsOutstanding: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onActionsOutstanding:Observable<boolean> = this._actionsOutstanding.asObservable(); 

  private _assistReset: Subject<boolean> = new Subject<boolean>();
  public readonly onAssistReset:Observable<boolean> = this._assistReset.asObservable(); 

  private _assistResetComplete: Subject<boolean> = new Subject<boolean>();
  public readonly onAssistResetComplete:Observable<boolean> = this._assistResetComplete.asObservable(); 

  private _layoutOptions: Subject<LayoutOptionType> = new Subject<any>();
  public readonly onLayout:Observable<LayoutOptionType> = this._layoutOptions.asObservable();  

  private _isWorkflowValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly onWorkflowValidity:Observable<boolean> = this._isWorkflowValid.asObservable();  

  private invalidStepCount : number = 0;

  private messagePoster = new MessagePoster();

  private checkSessionExpired : boolean = false;

  private checkSessionSubscription : Subscription = null;
  
  private contextService : IContextService;

  constructor(
    private http: HttpClient
  ) {

    this.addEventListeners();
    this.serverSuffix = '/api/assist';  //?XDEBUG_SESSION_START=netbeans-xdebug

  };

  setContextService (contextService: IContextService) {
    this.contextService = contextService;
  }

  getBaseUrl() :string {

    return this.contextService.getBaseUrl();
  }

  setActive(value : boolean) {
    this.isActive = value;
  }

  activateTab() : void {
    this.messagePoster.postMessage('fromAppActivateTab', {});
  }

  setSystemInProgress(value: boolean) {
    this._systemInProgress.next(value);
  }

  setStepProcessText(text: string) {
    this._stepProcessText.next(text);
  }
  
  clearStepProcessText() {
    this._stepProcessText.next("");
  }

  logger(type : EnumLogType, text: string) {
    this._runtimeError.next(text);
  }

  consoleLog(text: string) {
    this._consoleLog.next(text);
  }

  setLayoutOptions(options: any) {
    this._layoutOptions.next(options as LayoutOptionType);
  }

  resetLayout() {

    let options = this.contextService.getDefaultLayout();

    this._layoutOptions.next(options);
  }

  setScriptIds (scriptId : number = 0, scriptInstanceId : number = 0) {
    this.scriptId = scriptId;
    this.scriptInstanceId = scriptInstanceId;

    this.startSessionTimer();
  }

  get hasScriptLoaded () : boolean {
    return this.scriptId !== 0;
  }

  setUserCancel(cancelled : boolean) {

    if (!cancelled || this._scriptRunning.getValue()) {
      this._userCancel.next(cancelled);

      if (cancelled) {
        this.setScriptRunning(false);
      }
    }
  }

  get isUserCancelled() : boolean {
    return this._userCancel.getValue();
  }

  startSessionTimer() {

    if (this.checkSessionSubscription) {
      this.checkSessionSubscription.unsubscribe();
      this.checkSessionSubscription = null;
    }

    let timeout = 1*20*1000;
    this.checkSessionExpired = false;

    this.checkSessionSubscription = timer(timeout).subscribe(() => {

      this.checkSessionSubscription.unsubscribe();
      this.checkSessionSubscription = null;

      this.checkSessionExpired = true;

    });
  }

  checkSession() : Promise<boolean> {

    if (this.contextService.hasSession) {

      return new Promise<boolean>((resolve) => {

        if (this.checkSessionExpired) {
    
          this.testSession().subscribe(hasSession => {
            if (!hasSession) {
              this.displayOffLineError();
              resolve(false);
            } else {
              this.startSessionTimer();
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      });
    } else {
      return Promise.resolve(true);
    }
  }

  assistReset() : Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      let subscription = this.onAssistWait.subscribe(data => {

        subscription.unsubscribe();

        subscription = this.onAssistResetComplete.subscribe(data => {
          
          subscription.unsubscribe();
          
          this.doReset();      
          
          resolve(true);
        })   
        
        // This tells the add-on to stop any running or waiting macros - send ResetComplete message when done
        this.messagePoster.postMessage('fromAppReset', {});
        
      })   
      
      // This set flag used by StepBranch to stop processing steps
      this.setUserCancel(true);
      // This is used by the Action View to close any macro waiting dialogs
      this._assistReset.next(true);

    });
  }

  doReset() {
    this.setScriptIds();
    this.setScriptRunning(false);
    this.setActionsOutstanding(false);
    this.setSystemInProgress(false); 
    this.invalidStepCount = 0;
    this._isWorkflowValid.next(true);     
  }

  assistDeactivate() {

    // This set flag used by StepBranch to stop processing steps
    this.setUserCancel(true);
    // This is used by the Action View to close any macro waiting dialogs
    this._assistReset.next(true);

    // This tells the add-on to stop any running or waiting macros - send ResetComplete message when done
    this.messagePoster.postMessage('fromAppReset', {});

    this.doReset();  

  }

  resetComplete(data) {
    this._assistResetComplete.next(data);
  }

  setScriptRunning(running : boolean) {
    this._scriptRunning.next(running);
  }
  
  get isScriptRunning () : boolean {
    return this._scriptRunning.getValue();
  } 
  
  setActionsOutstanding(outstanding : boolean) {
    this._actionsOutstanding.next(outstanding);
  }

  get hasActionsOutstanding() : boolean {
    return this._actionsOutstanding.getValue();
  }

  loadConfig (requestData : any) : Observable<IAssistConfigResponse> { 

    var appId: string = "";
    var locationId: string = "";
    var scriptId: string = "";
    var urlData: string = "";

    if (requestData.hasOwnProperty("appId")) {
      appId = ""+requestData.appId;
    }
    if (requestData.hasOwnProperty("locationId")) {
      locationId = ""+requestData.locationId;
    }
    if (requestData.hasOwnProperty("scriptId")) {
      scriptId = ""+requestData.scriptId;
    }
    if (requestData.hasOwnProperty("guid")) {
      urlData = JSON.stringify({g:requestData.guid});
    }

    let url : string = this.getConfigLoadUrl(appId, locationId, scriptId, urlData);

    let http$ = this.http.get<IAssistConfigResponse>(url, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  loadEthicsGen (strScriptName : string) : Observable<IAssistConfigResponse> { 

    let url : string = this.getEthicsGenUrl(strScriptName);

    let http$ = this.http.get<IAssistConfigResponse>(url, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$; 

  }


  loadEthicsGenConfig (strScriptName : string) : Subscription {     

    let request$ = this.loadEthicsGen(strScriptName).pipe (
      map(response => {
        if (this.handleResponse(response)) {
          this.workflowData = {values: {scriptId : response.response.id}};
          this.setScriptIds(this.workflowData.values.scriptId, response.response.instanceId);
          this.startWorkFlow();
        }
        this.isLoading = false;
        return true;
      }),
      catchError (err => {
        this.isLoading = false;
        return throwError(err) 
      })
    ).subscribe();

    return request$;
  }

  testSession () : Observable<boolean> {

    let url : string = this.getSessionCheckUrl();

    let http$ = this.http.get<IAssistSessionResponse>(url, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => {
        let result = false;
        if (response && response.hasOwnProperty("resultCode") && response.resultCode == 0) {
          result = true;      
        }
        return result;
      }),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  metaDataCheck (metaDataName: string, params: any, metadata: any) : Observable<any> {

    let url : string = this.getMetaDataUrl(metaDataName);

    let http$ = this.http.post<any>(url, {name: metaDataName, params: params, metadata: metadata}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  generateUUID = function () {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
    });
    return uuid;
 } 

  createDocument (libName: string, sourceName: string, targetName: string, docData: any) : Observable<any> {

    let url : string = this.getCreateDocUrl(sourceName);

    let requestId = this.generateUUID();

    let http$ = this.http.post<any>(url, {libName: libName, sourceName: sourceName, targetName: targetName,  docData: docData, requestId : requestId}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  writeLog (logTypeCode: string, params: any) : Observable<any> {

    let dt = new Date();

    let url : string = this.getWriteLogUrl(this.scriptInstanceId, logTypeCode);

    let http$ = this.http.post<any>(url, {scriptId: this.scriptId, stamp: dt.getTime()/1000, log:params}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  getEthicsGenUrl(strScriptName : string) : string {
    let result : string =  '/apiEthicsGen.php/script/' + strScriptName + "?XDEBUG_SESSION_START=netbeans-xdebug";
    
    return result; 
  }  

  getConfigLoadUrl(appId: string, locationId: string, scriptId: string, urlData : string) : string {
    let result : string =  '/' + this.getBaseUrl() + this.serverSuffix + '/app/' + appId + '/loc/' + locationId + '/script/' + scriptId;

    if (urlData !== "") {
      result += "?data=" + btoa(urlData);
    }

    return result; 
  }  

  getSessionCheckUrl() : string {
    return '/' + this.getBaseUrl() + this.serverSuffix + '/session/' + 1;; 
  }  

  getMetaDataUrl(metaDataName: string) : string {
    return '/' + this.getBaseUrl() + this.serverSuffix + '/meta/' + metaDataName;
  }  

  getCreateDocUrl(sourceName : string) : string {
    return '/' + this.getBaseUrl() + this.serverSuffix + '/create/'+ sourceName;
  }  

  getWriteLogUrl(scriptInstanceId: number, logTypeCode: string) : string {
    return '/' + this.getBaseUrl() + this.serverSuffix + '/log/' + logTypeCode + '/inst/' + scriptInstanceId;
  }  

  getDownloadUrl(requestId: string) : string {
    return '/' + this.getBaseUrl() + this.serverSuffix + '/?download=' + requestId;
  }  

  assistWait(data: any) {
    this._assistWait.next(data);  
  }

  setWaitResult(data: any) {
    this._assistWaitResult.next(data);
    if (data && data.cancel) {
      this.messagePoster.postMessage("fromAppAssistWaitAbort", data );
    }
  }

  displayOffLineError() {

    let msgData : IMsgBoxData = {
      title: "Workflow Assist Error", 
      message : ["The Carina session has timed out.", "", "You must sign-on and restart Workflow Assist."],
      buttons : [{text: "Return to sign-on", value: 0}]
    };

    this._displayMsgBox.next(msgData);

    let subscription = this.onMsgBoxResult.subscribe(value =>{

      subscription.unsubscribe();

      this.contextService.navigate("/signon");

    });
  }

  async canRetryError(dataValue : ValueBase): Promise<boolean> {

    return new Promise<boolean> ((resolve, reject) => {

      let msgData : IMsgBoxData = {
        title: "Workflow Assist Error", 
        message : [],
        buttons : [{text: "Cancel", value: 0}, {text:"Retry", value :1}]
      };

      if (dataValue.errData && dataValue.errData.errNum == 100) {
        msgData.message = ["Cannot find page " + dataValue.errData.data.pageType];
      }
  
      this._displayMsgBox.next(msgData);

      let subscription = this.onMsgBoxResult.subscribe(value =>{

        subscription.unsubscribe();

        if (value == 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async displayError(errorText : string | string[], canRetry : boolean): Promise<boolean> {

    let arrErrorText : string[];

    if (Array.isArray(errorText)) {
      arrErrorText = errorText;
    } else {
      arrErrorText = [errorText];
    }

    let buttons = [];

    if (canRetry) {
      buttons.push({text: "Cancel", value: 0},{text:"Retry", value :1});
    } else {
      buttons.push({text: "OK", value: 0});
    }

    return this.createMessage("Workflow Assist Error", arrErrorText, buttons);

  }

  async createMessage (title : string, msgText : string [], buttons : any[]) {

    return new Promise<boolean> ((resolve, reject) => {

      let msgData : IMsgBoxData = {
        title: title, 
        message : msgText,
        buttons : buttons
      };

      this._displayMsgBox.next(msgData);

      let subscription = this.onMsgBoxResult.subscribe(value =>{

        subscription.unsubscribe();

        if (value == 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    });
  }

  async canStartScript () : Promise<boolean> {

    if (this.hasScriptLoaded && (this.isScriptRunning || this.hasActionsOutstanding)) {

      let message : string [];

      if (this.isScriptRunning) {
        message = [ 
          "Workflow Assist is already running a script.", 
          "",  
          "Do you want to quit the running script?",
        ]
      } else {
        message = [ 
          "The current Workflow Assist script still has incomplete actions.", 
          "",  
          "Do you want to quit the current script without completeing all available actions?",
        ]
      }

      message.push(
        "", 
        "Press Yes to start a new script.", 
        "Press No to continue with the currently running script."
      );

      this.activateTab();

      return this.createMessage (
        "Worflow Assist", message,
        [
          {text: "Yes", value: 1}, 
          {text:"No", value: 0}
        ]);
    } else {
      return true;
    }
  }

  setRetryResult(value: any) {
    this._msgBoxResult.next(value);
  }

  private _debugLevel: BehaviorSubject<EnumDebugLevel> = new BehaviorSubject(EnumDebugLevel.debug_info);
  public readonly debugLevel: Observable<EnumDebugLevel> = this._debugLevel.asObservable();   

  private _assistConfig: Subject<AssistConfig> = new Subject();
  public readonly workflowConfig: Observable<AssistConfig> = this._assistConfig.asObservable();   

  private _startWorkflow: Subject<number> = new Subject();
  public readonly startWorkflow: Observable<number> = this._startWorkflow.asObservable();   

  private _resetWorkflow: Subject<number> = new Subject();
  public readonly resetWorkflow: Observable<number> = this._resetWorkflow.asObservable();   

  addEventListeners() {
    window.addEventListener("message", (event) => {

      // We only accept messages from ourselves
      if (event.source != window) {
        return;
      }

      var type = event.data?.type;
      var data = event.data?.data;

      if (type) {

        switch (type) {
          case 'toAppInstalled':
            this.carinaInstalled(); 
          break;
          case 'toAppLoadWorkflow':
            this.getAssistConfig(data);
          break;
          case 'toAppStartWorkflow': 
            this.startWorkFlow();
          break;
          case 'toAppReturnValue': 
            this.setWorkflowValue(data);
          break;
          case 'toAppMacroComplete': 
            this.completeMacro(data);
          break;
          case 'toAppAssistWait': 
            this.assistWait(data);
          break;
          case 'toAppResetComplete': 
            this.resetComplete(data);
          break;
        }
      }
    });    
  }

  carinaInstalled () {

    if (this.isActive) {
      this.messagePoster.postMessage('fromAppAssistOpen', {});  
    }
  }

  async getAssistConfig(workflowData: any) : Promise<boolean> {

    let result = false;

    if (this.isActive && !this.isLoading) {

      this.isLoading = true;

      result = this.validateVersion(workflowData);
    
      if (result) {

        if (await this.canStartScript()) {
          await this.reset();
        } else {
          result = false;
        }

      }

      if (result) {
        this.workflowData = workflowData;
        this.loadAssistConfig(workflowData);
      } else {

        this.isLoading = false;
      }
    }

    return result;
  }

  validateVersion(workflowData: any) : boolean {

    let result : boolean = false;

    if (workflowData.hasOwnProperty("version")) {
      let reqVersion = environment.addOnVersion.split(".", 3);
      let curVersion = workflowData.version.split(".",3);
      
      let index : number = 0;
      result = true;

      while ((result == true) && 
             (index < 3) && 
             (parseInt(reqVersion[index]) >= parseInt(curVersion[index]))) {
        if (parseInt(reqVersion[index]) > parseInt(curVersion[index])) {
          result = false;
        }

        index++;
      }
    }

    if (!result) {
      this.displayError("Workflow Assist requires Carina browser extension version " + environment.addOnVersion + " or later.  Please install the latest version.", false);
    }

    return result;
  }

  loadAssistConfig(workflowData: any) : Subscription {

    let request$ = this.loadConfig(workflowData.values).pipe (
      map(response => {
        if (this.handleResponse(response)) {
          this.workflowData = workflowData;
          this.setScriptIds(workflowData.values.scriptId, response.response.instanceId);
        }
        this.isLoading = false;
        return true;
      }),
      catchError (err => {
        this.isLoading = false;
        return throwError(err)
      })
    ).subscribe();
   
    return request$;

  }

  handleResponse(response : IAssistConfigResponse) : boolean {

    let result = false;

    if (response && response.hasOwnProperty("resultCode")) {

      if (response.resultCode == -1) {

        this.displayOffLineError();
      
      } else if (response.resultCode != 0) {
        
        this.displayError(response.errors, false);

      } else if (response.hasOwnProperty("response") && response.response) {

        result = true;  
    
        this.config = AssistConfig.getInstance();
  
        this.setUserCancel(false);
        this.config.initialise(response.response);
      
        this.setAssistConfig();
      
        if (result) {
          this.messagePoster.postMessage("fromAppWorkflowLoaded", { workflowId: 1, appConfig: this.config.appConfig } );
        }
      }
    }

    return result;
  }

  setAssistConfig() {
    this._assistConfig.next(this.config);
  }
  
  async reset() {

    await this.assistReset(); 

    this._resetWorkflow.next(1);   
    if (this.config) {
      this.config.reset();
    }
  }  

  startWorkFlow () {

    if (this.hasScriptLoaded) {
      this._startWorkflow.next(1);

      if (this.workflowConfig) {
        this.resetLayout();
        this.setScriptRunning(true);
        this.config.startWorkflow();
      }
    }
    //this.getWorkflowFields();

  }

  setWorkflowValue(data : any) {

    if (this.hasScriptLoaded && data.hasOwnProperty("name")) {
      this.config.assistValues.setPendingValue(data.name, data);
    }
  }

  completeMacro (data : any) {

    if (this.hasScriptLoaded && data.hasOwnProperty("macroName")) {
      this.config.assistValues.completeMacro(data.macroName, data);
    }
  }

  handleWaitResult(data : any) {
    if (data && data.cancel) {
      this.messagePoster.postMessage("fromAppAssistWaitAbort", data );
    }
  }

  stepValidityChange(isValid : boolean) {

    if (isValid) {
      this.invalidStepCount--;
      if (this.invalidStepCount == 0) {
        this._isWorkflowValid.next(true);
      }
    } else {
      this.invalidStepCount++;
    
      if (this.invalidStepCount == 1) {
        this._isWorkflowValid.next(false);
      }
    }
  }

  getWorkflowValidity() : boolean {

    return this._isWorkflowValid.getValue();

  }  

}