import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssistValues } from '../../classes/assist-values';
import { ValueBoolean } from '../../classes/value-boolean';
import { StepValueItem } from '../../classes/step-value-item';
import { IValueComponent } from '../../interfaces/ivalue-component';

@Component({
  selector: 'carina-value-boolean',
  templateUrl: './value-boolean.component.html'
})
export class ValueBooleanComponent implements OnInit, OnDestroy, IValueComponent {

  @Input() stepData: StepValueItem;
  dataValue: ValueBoolean;
  valueText = "false";

  private assistValues: AssistValues;
  private _subscriptions: Subscription[] = [];

  constructor() { 
    this.assistValues = AssistValues.getInstance();

  }

  ngOnInit(): void {
    
    if (!this.findDeclaredValue()) {

      let subscription : Subscription = 
        this.assistValues.onValueDeclared.subscribe(valueName => {
          if (valueName == this.stepData.valueName) {
            this.findDeclaredValue();
            subscription.unsubscribe();
          }
        });
      this._subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  findDeclaredValue() : boolean {

    let result = false;

    this.dataValue = this.stepData.scope.findValue(this.stepData.valueName) as ValueBoolean;

    if (this.dataValue) {
      result = true;

      this._subscriptions.push(
        this.dataValue.onChangeValue.subscribe(value => {
          this.setText();
        })
      );
    }

    return result;
  }

  setText() {
    if (this.dataValue.getValue()) {
      if (this.stepData.style == "OK") {
        this.valueText = "OK";
      } else {
        this.valueText = "OK";
      }
    } else {
      if (this.stepData.style == "OK") {
        this.valueText = "not OK";
      } else {
        this.valueText = "false";
      }
    }
  }
 
}