export enum EnumEventType {

  branchComplete,
  branchIncomplete,
  stepComplete,
  stepIncomplete,
  stepCallComplete,
  branchCallComplete,
  errorEvent,
  actionEntrySort,
  actionCompleted,
  actionEntryUpdated
}
