
export class MessagePoster {

  serverPrefix:string;

  constructor() {
    
  }

  postMessage (type : string, data : any ) {
    window.postMessage({ type: type, data: data }, window.location.origin);
  }

}
