import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";


export class ValueObject extends ValueBase {
  value : object;

  constructor(config: IDataValue) {
    super(config);

    //this.assistEval = AssistEval.getInstance();
    this.value = null;

    if (config.hasOwnProperty("value") && typeof config.value === "object" && config.value !== null) {
      this.setValue(config.value);
    }
  }

  get isArray() : boolean {
    return Array.isArray(this.value);
  }

  get isObjectArray() : boolean {
    return this.isArray && this.value["length"].length > 0 && typeof this.value[Object.keys(this.value)[0]] === "object";
  }

  get isPlural() : boolean {
    return this.isArray ? this.value["length"] > 1 : false;
  }

  reset() {
    super.reset();
    this.value = null;
  }

  canCast(newType : EnumValueType) : boolean {
    return newType === EnumValueType.Value_object;
  }

  getValue() : object {
    return this.value;
  }

  setValue(value: object): void {
    this.value = value;
    super.setValue(value);
    this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {

    if (newValue.isObject) {
      this.value =  (newValue as ValueObject).value;
    } else {
      this.value = null;
    }

    super.assignValue(newValue);

  }

  arrayConcat(separator : string, lastSeparator : string) : string {
    let concatText = "";
    let sep = "";

    if (this.isArray) {

      let strValues : string[] = (this.value as string[]);
      let length = Object.keys(strValues).length;
      let index = 0;

      strValues.forEach((entry) => {

        if (typeof entry === "string") {
          concatText += sep + entry;

          if (index < length - 2) {
            if (sep === "") {
              sep = separator;
            }
          } else {
            sep = lastSeparator;
          }
        }  

        index++;

      });
    }

    return concatText;

  }

  arrayCount() : number {

    let count : number = 0;

    if (this.isArray) {
      count = Object.keys(this.value).length;
    }
    
    return count;
  }  

  arrayLength() : number {

    let length : number = 0;

    if (this.isArray) {
      length = (this.value as any[]).length;
    }
    
    return length;
  }    
}
