import { Component, OnInit, Input } from '@angular/core';
import { StepPrompt} from '../../classes/step-prompt';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-yn',
  templateUrl: './prompt-yn.component.html'
})
export class PromptYNComponent extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPrompt;
  toggleValue: boolean = false;
  isToggle: boolean = false;

  constructor() { 
    super();  
  }

  ngOnInit(): void {
    if (this.stepData.type == EnumStepType.Prompt_Toggle) {
      this.isToggle = true;
      this.toggleValue = this.stepData.value == 'yes';
    }
  }

  process() {
    this.stepData.processChoice(this.stepData.value as string);
  }

  onMouseOver() {
    this.stepData.setHighlights(true);
  }

  onMouseOut() {
    this.stepData.setHighlights(false);
  } 

  slide() {
    this.stepData.processChoice(this.toggleValue ? "yes": "no");
  }


}