import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { StepActionButton } from '../../classes/step-action-button';
import { IActionComponent } from '../../interfaces/iaction-component';
import { AssistStateService } from '../../services/assist-state.service';

@Component({
  selector: 'carina-action-button',
  templateUrl: './action-button.component.html'
})
export class ActionButtonComponent implements  IActionComponent {

  @Input() stepData: StepActionButton;
  actionType: string = "statement";
  actionInProgress : boolean = false;
  systemInProgress : boolean = false;

  private _subscriptions : Subscription [] = [];

  constructor(
    private assistStateService: AssistStateService
  ) { }

  process(): void {
    this.callProcess();
  }

  async callProcess() {
    this.actionInProgress = true;
    this.assistStateService.setSystemInProgress(true);
    await this.stepData.processAction();
    this.assistStateService.setSystemInProgress(false);
    this.actionInProgress = false;
  }
}
