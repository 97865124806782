
import { TEXT_COLUMN_OPTIONS } from "@angular/cdk/table";
import { EnumEventType } from "../enums/enum-event-type";
import { EnumValueItemType } from "../enums/enum-value-item-type";
import { EnumValueState } from "../enums/enum-value-state";
import { IStepValueItem } from "../interfaces/istep-value-item";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { EvalNode } from "./eval-node";
import { StepBase } from "./step-base";
import { ValueBase } from "./value-base";

export class StepValueItem extends StepBase {

  valueItemType : EnumValueItemType;
  class : string;
  state : EnumValueState;
  label : string;
  valueName : string;
  dataValue : ValueBase;
  style: string;
  viewConfig: any = {};
  viewConfigEval: EvalNodeType = null;
  actionEvals : EvalNodeType[] = [];

  private _config : IStepValueItem;

  constructor(index: number, config : IStepValueItem) {
    super(index, config);

    this.state = EnumValueState.FieldState_Value;
    this.valueItemType = config.valueItemType;
    this.valueName = config.valueName;
    this.label = config?.label;
    if (this.label == null) {
      this.label = "";
    }
    this.style = config?.style;
    if (this.style == null) {
      this.style = ""; 
    }

    this.dataValue = null;

    this._config = config;

  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.hasOwnProperty("viewConfig")) {
      evalNodes.push(this._config.viewConfig); 
    } 

    if (this._config.hasOwnProperty("actions") && this._config.actions.length > 0) {
      this._config.actions.forEach(evalNode => evalNodes.push(evalNode));
    }

    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.hasOwnProperty("viewConfig")) {
      this.viewConfigEval = evalNodes.splice(0,1)[0];
    } 

    if (this._config.hasOwnProperty("actions") && this._config.actions.length > 0) {
      evalNodes.forEach(evalNode => this.actionEvals.push(evalNode));
    }

    this._config = null;

  }  

  private async setViewConfig (evalNode : IEvalNodeType) {

    this.viewConfig = {};

    try {
      let configDataValue = await this.assistEval.evaluate(evalNode);
      if (configDataValue.isValue && configDataValue.isObject) {
        this.viewConfig = configDataValue.getValue();
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  async activate(): Promise<boolean> {
    let result: boolean;

    result = await super.activate();

    if (this.viewConfigEval != null) {
      await this.setViewConfig(this.viewConfigEval);
    }

    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }  

  async processAction(procName : string, value : string, index: number, entry: any) {

    let procNode : EvalNodeType = null;

    if (this.actionEvals.some((evalNode : EvalNode) => {
      if (evalNode.name == procName) {
        procNode = evalNode;
        return true;
      }
      return false;
    })) {
      await this.assistEval.evaluate(procNode, [value, index, entry]);
    }
  }

}