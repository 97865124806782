import { Component, OnInit, Input} from '@angular/core';
import { StepPrompt } from '../../classes/step-prompt';
import { ValueBase } from '../../classes/value-base';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-se',
  templateUrl: './prompt-entry.component.html'
})
export class PromptEntryComponent  extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPrompt;

  public isTextarea : boolean = false;
  public hideButton : boolean = false;
  public rows : number = 10;
  public name : string = "";
  public isPassword : boolean = false;
  public bHidePassword : boolean = true;
  public hasError : boolean = false;

  constructor() {
    super(); 
  }

  ngOnInit(): void {

    this.getGroupOptions(this.stepData);

    var promptConfig = this.stepData.promptConfig;

    if (promptConfig.hasOwnProperty("type") && promptConfig.type == "textarea") {
      this.isTextarea = true;    
      if (promptConfig.hasOwnProperty("rows") && promptConfig.rows > 0 && promptConfig.rows < 10) {
        this.rows = promptConfig.rows;
      }
    }

    if (promptConfig.hasOwnProperty("hideButton") && promptConfig.hideButton === true) {
      this.hideButton = promptConfig.hideButton;
    }

    if (promptConfig.hasOwnProperty("name")) {
      this.name = promptConfig.name;
    }

    if (promptConfig.hasOwnProperty("password")) {
      this.isPassword = promptConfig.password === true;
    }
    
    let dataValue : ValueBase = this.stepData.dataValue;

    this._subscriptions.push(dataValue.onChangeValue.subscribe(value => {

        this.setStepValue(value);

      })
    );
    
    this.setStepValue(dataValue.getValue());
  }

  get borderColor() : string {
    let result = "primary";

    if (!this.stepData.isValid) {
      result = "warn";
    } else if (!this.stepData.isPristine) {
      result = "accent";
    }


    return result;
  }

  get isValid() : boolean {
    return this.stepData.isValid || this.stepData.isPristine;
  }
 
  setStepValue(value : any) {

    let dataValue : ValueBase = this.stepData.dataValue;

    if (this.stepData.type == EnumStepType.Prompt_List) {

      let dataValue : ValueBase = this.stepData.dataValue;

      if (dataValue.isArray) {
        this.stepData.value = value.join();    
      } 
    } else {
      this.stepData.value = value;
    }
  }

  process(choice: string) {
    this.stepData.processNext();
  }

  change() {
    this.stepData.updateValue();
  }

  onFocus() {
    this.stepData.setHighlights(true);
  }

  onBlur() {
    this.stepData.setStatus();
    this.stepData.setHighlights(false);
  }

}
