import { Component } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service'
import { Breakpoints, BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'carina-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'carina';
  strLayout = 'default';
  strClass = "Page";

  constructor(
    private appService: AppService,
    private responsive: BreakpointObserver
  ) {


    console.log('Medium ' + Breakpoints.Medium);
    console.log('Large ' + Breakpoints.Large);
    console.log('XLarge ' + Breakpoints.XLarge);

    this.appService.pageLayout.subscribe(strLayout => {

    });    

      this.responsive.observe([
        Breakpoints.Large,
        Breakpoints.XLarge
        ])
        .subscribe(result => {

          this.strClass = "page";

          if (result.matches) {
            this.strClass = "max-page";
          }

    });

  }
}

