import { Component, OnInit, Input } from '@angular/core';
import { ValueBaseComponent } from './value-base.component';

export interface IPanelEntry {
  title : string;
  content : string;
}

@Component({
  selector: 'carina-value-panel',
  templateUrl: './value-panel.component.html',
  styles: [
  ]
})
export class ValuePanelComponent extends ValueBaseComponent implements OnInit {

  public entries : IPanelEntry [];

  constructor() { 
    super();
  }

  ngOnInit(): void {

    super.ngOnInit();

  }

  findDeclaredValue() {

    let result = super.findDeclaredValue();

    if (result && this.dataValue) {

      result = true;

      this._subscriptions.push (
        this.dataValue.onChangeValue.subscribe(value => {
          this.handleValue();
        })
      );
    }
    
    return result;
  }

  handleValue () {

    let isArray : boolean;
    let strValue : string = "";

    this.entries = [];

    if (this.dataValue.isValue) {
      if (this.dataValue.isArray) {
        let arrValue : any[] = this.dataValue.getValue() as unknown as any[];

        arrValue.forEach(element => {

          if (element.hasOwnProperty("title")) {

            let entry : IPanelEntry = {
              title: element.title, 
              content: ""};

            if (element.hasOwnProperty("content")) {
              entry.content = element.content;
            }  

            this.entries.push(entry);

          }    
        })
      } else {
        strValue = this.dataValue.getValue() as string;

        let entry : IPanelEntry = {
          title: strValue, 
          content: ""};
          
        this.entries.push(entry);        
      }
    }    
  }
}
