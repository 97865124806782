<ng-container *ngIf="stepData.active" >
  <div  class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div #scrollTarget  class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div #scrollTarget class="stepButtons">
    </div> 
  </div>
  <div class="assistStep" >
    <div class="stepOffset">
    </div>
    <div class="stepButtons">
      <div class="stepButtonEnd">
        <button *ngFor="let entry of stepData.arrEntryText;index as idx" mat-stroked-button (click)="select(idx)" value="{{stepData.arrEntryValue[idx]}}">{{entry}}</button>
      </div>
    </div>
  </div>    
</ng-container>