<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="valueLabel">{{stepData.label}}</span>
      <div *ngIf="isPending">
        <div class="valuePendingLarge"><img src="/assets/images/CarinaInprogress_32x32.gif"></div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngIf="isValue">
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let entry of entries" [hideToggle]="entry.content.length==0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="valuePanelTitle">
              {{entry.title}}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="entry.content.length>0" class="valuePanelContent">
          <span class="valueContent">{{entry.content}}</span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="isPending">
    <div class="valuePendingLarge"><img src="/assets/images/CarinaInprogress_32x32.gif"></div>
  </div>
</mat-expansion-panel>