
import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { AssistValues } from '../../classes/assist-values';
import { StepValueItem } from '../../classes/step-value-item';
import { ValueBase } from '../../classes/value-base';
import { IValueComponent } from '../../interfaces/ivalue-component';

@Component({
  template: '',
  styleUrls: []
})
export class ValueBaseComponent implements OnInit, OnDestroy, IValueComponent {

  @Input() stepData: StepValueItem;
  protected assistValues: AssistValues;
  protected _subscriptions: Subscription[] = [];  
  public expanded : boolean;

  dataValue: ValueBase;  

  constructor() { 

    this.assistValues = AssistValues.getInstance();
    this.dataValue = null;

  }

  ngOnInit() : void {

    this.expanded = this.stepData.viewConfig.hasOwnProperty("expand") && this.stepData.viewConfig.expand == true;

    if (!this.findDeclaredValue()) {

      let subscription : Subscription = 
        this.assistValues.onValueDeclared.subscribe(valueName => {
          if (valueName == this.stepData.valueName) {
            this.findDeclaredValue();
            subscription.unsubscribe();
          }
        });
      this._subscriptions.push(subscription);
    } 

  }

  ngOnDestroy(): void{
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  findDeclaredValue() {

    let result = false;

    this.dataValue = this.stepData.scope.findValue(this.stepData.valueName);

    if (this.dataValue) {

      result = true;

    }

    return result;
  }


  get isValue () : boolean {
    return this.dataValue && this.dataValue.isValue;
  }
  get isPending(): boolean {
    return this.dataValue && this.dataValue.isPending;
  }
  get isError(): boolean {
    return this.dataValue && this.dataValue.isError;
  }  
}
