
import { IStepEval } from "../interfaces/istep-eval";
import { BranchData } from "./step-branch";
import { StepBase } from "./step-base";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { AssistEval } from "./assist-eval"; 
import { EventData } from "./event-data";
import { EnumEventType } from "../enums/enum-event-type";
import { ValueBase } from "./value-base";
import { EnumValueType } from "../enums/enum-value-type";
import { EnumValueState } from "../enums/enum-value-state";
import { EnumStepType } from "../enums/enum-step-type";

export class StepEvalBranch extends StepBase {

  public eval: EvalNodeType;
  public errorText: string;
  public overrideText: string;
  public branches: BranchData[];
  public isOverriden: boolean = false;
  public canOverride: boolean = false;
  public outputText: string = "";
  public valueName : string = "";
  public valueType : EnumValueType;
  public dataValue : ValueBase;
  public valueNames : string[] = [];
  public whenDeep: boolean = false;
  public groupOptions : any = {};
  public valueText = {true: "branch is true", false: "branch is false"};

  private _config : IStepEval;
  
  constructor(
    index: number,
    config: IStepEval,
    defaultName : string
  ) {
    
    super(index, config);
        
    //let arrNames: string[] = [];

    this.valueType = config.valueType;
    if (config.hasOwnProperty("valueName")) {
      this.valueName = config.valueName;
    } else {
      this.valueName = defaultName;
    }  

    this.dataValue = null;

    this.branches = [];    

    if (config.hasOwnProperty("valueText")) {
      this.valueText = config.valueText;
    }
    if (config.hasOwnProperty("errorText")) {
      this.errorText = config.errorText;
    }
    if (config.hasOwnProperty("overrideText")) {
      this.overrideText = config.overrideText;
    }

    if (config.hasOwnProperty("valueNames")) {
      this.valueNames = config.valueNames;
    }

    if (config.hasOwnProperty("whenDeep")) {
      this.whenDeep = config.whenDeep;
    }

    this._config = config;

  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.eval !== undefined && this._config.eval !== null) {
      evalNodes.push(this._config.eval);
    }

    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.eval !== undefined && this._config.eval !== null) {
      this.eval = evalNodes.splice(0,1)[0];
    }

    this._config = null;

  }

  getGroupOptions() : any{

    let options = super.getGroupOptions();

    if (this.type == EnumStepType.Eval_Group ) {
      
      Object.assign(options, this.groupOptions);
    }

    return options
  }

  getStepValueName() {
    return this.valueName;
  }

  addBranch(branchData : BranchData) {
    this.branches.push(branchData);

    this._subscriptions.push (
      branchData.branchEvent.subscribe(event => {
        this.handleBranchEvent(event);
      })
    );
  }

  initialise(topBranch : BranchData) {
    super.initialise(topBranch);
    this.branches.forEach(branch => {
      branch.initialise(topBranch);
    })
  }

  destroy() {    
    super.destroy();
    this.branches.forEach(branch => branch.destroy());
  }  

  async activate(): Promise<boolean> {
    let result: boolean = false;

    result = await  super.activate();

    if (this.dataValue === null) {

      this.dataValue =  this.scope.findDeclareValue({type:EnumValueType.Value_boolean, name: this.valueName});

      this.valueNames.forEach(valueName => {

        let dataValue = this.scope.findValue(valueName);

        if (dataValue) {
          this._subscriptions.push (
            dataValue.onChangeValue.subscribe((value) => {
              if (this.active) {
                if (this.whenDeep) {
                  this.deactivate(); 
                  this.activate(); 
                } else {
                  this.evaluate(); 
                }
              }  
            })
          );
        }
      });
    }

    if (this.type == EnumStepType.Eval_Group) {
      if (this.eval) {
        this.groupOptions = await (await this.assistEval.evaluate(this.eval)).getValue();
      }
      this.processGroupBranch();
    } else {
      this.evaluate();
    }
    
    return result;
  }

  deactivate(): boolean {
    let result: boolean = false;

    result = super.deactivate();

    if (this.dataValue) {
      this.dataValue.setState(EnumValueState.FieldState_Undefined);
    }

    if (this.branches.length == 0) {
      this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });
      result = true;
    } else {
      this.hideBranches();
    }

    return result;
  }

  private async evaluate(): Promise<boolean>{

    let notSet = !this.dataValue.isValue;
    
    let dataValue: ValueBase;
    let currentValue : boolean = this.dataValue.cast(EnumValueType.Value_boolean) as boolean;
    let newValue : boolean;
    let updateBranch : boolean = false;

    this.outputText = "";

    dataValue = await this.assistEval.evaluate(this.eval);

    if (dataValue.isValue) {
      newValue = dataValue.cast(EnumValueType.Value_boolean) as boolean;
      
      if (notSet || newValue != currentValue) {
        updateBranch = true;
        this.dataValue.setValue(newValue);
      }
    } else {

      this.dataValue.assignValue(dataValue);
      this.processText = "Error: " + this.dataValue.error;
      
      this.hideBranches();
    }

    if (updateBranch) {

      //this.processText = "branch " + this.valueName + " is " + String(dataValue.getValue());
      this.processBranch();

    }

    return updateBranch;
  }

  override(): void {
    this.outputText = this.overrideText;
  }

  processOverride() {

    this.hideBranches();

    if (this.isOverriden) {
      this.override();
    } else {
      this.evaluate();
    }

  }

  hideBranches() {
    this.branches.forEach(branch => {
      branch.deactivateBranch();
    });
  }

  processBranch() {

    let activeIndex : number;
    if (!this.dataValue.isObject) {
      activeIndex = this.dataValue.cast(EnumValueType.Value_boolean) ? 0 : 1;
    }

    this.branches.forEach((branch, index) => {
      if (index == activeIndex) {
        branch.activateNext();
      } else {
        branch.deactivateBranch();
      }
    });
  }

  processGroupBranch() {
    let branch : BranchData = this.branches[0];

    if (branch) {
      branch.activateGroup();
    }

  }
  
  handleBranchEvent(event: EventData) {

    switch (event.type) {
      case EnumEventType.branchComplete:
        this.sendEvent(EnumEventType.stepComplete, { index: this.index });
        break;
      case EnumEventType.branchIncomplete:
        this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });
        break;
    }
  }

  async callStep() {

    let retrunedDataValue : ValueBase;
    super.activate();

    let dataValue : ValueBase;

    if (this.type == EnumStepType.Eval_Branch) {

      dataValue = await this.assistEval.evaluate(this.eval);

      if (dataValue.isValue) {

        let callIndex : number = dataValue.cast(EnumValueType.Value_boolean) ? 0 : 1;

        if (callIndex === undefined) {
          callIndex = 1;
        }
      
        retrunedDataValue = await this.branches[callIndex].callBranch();

        if (retrunedDataValue.isUndefined) {
          retrunedDataValue = null;
        }

        this.sendEvent(EnumEventType.stepCallComplete, { index: this.index, returnDataValue: retrunedDataValue});

      } else {
        retrunedDataValue = dataValue;
        this.sendEvent(EnumEventType.stepCallComplete, { index: this.index, returnDataValue: retrunedDataValue});
      }
    } else {

      dataValue = this.assistEval.castValue(await this.assistEval.evaluate(this.eval), EnumValueType.Value_boolean);
      retrunedDataValue = null;
      
      while (retrunedDataValue == null && dataValue.isValue && dataValue.getValue() == true) {

        retrunedDataValue = await this.branches[0].callBranch();

        if (retrunedDataValue.isUndefined) {
          retrunedDataValue = null;
        }

        dataValue = this.assistEval.castValue(await this.assistEval.evaluate(this.eval), EnumValueType.Value_boolean);

      }

      this.sendEvent(EnumEventType.stepCallComplete, { index: this.index, returnDataValue: retrunedDataValue});

    }
  }

}

