
import { EnumEventType } from '../enums/enum-event-type';
import { StepBase } from "./step-base";
import { ValueBase } from './value-base';
import { IStepText } from '../interfaces/istep-text';
import { BranchData } from './step-branch';
import { EvalNodeType, IEvalNodeType } from '../types/eval-type';

export class StepText extends StepBase {

  public configText: string;
  public text: string;
  public textEval: EvalNodeType;
  public valueNames: string[];
  public dataValues : ValueBase[] = [];
  public icon: string = "";

  protected _config : IStepText;
    
  constructor(
    index: number,
    config: IStepText
  ) {
    super(index, config);

    this.configText = config.text;
    this.promptConfig = {};
    
    if (config.hasOwnProperty("valueNames")) {
      this.valueNames = config.valueNames;
    } else {
      this.valueNames = [];
    }
    if (config.hasOwnProperty("icon")) {
      this.icon = config.icon;
    } 
    
    this.setPromptConfig(config);

    this._config = config;     
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.text !== undefined) {
      evalNodes.push(this._config.text);
    }
    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.text !== undefined) {
      this.textEval = evalNodes.splice(0,1)[0];
    }
    this._config = null;

  }

  initialise(topBranch : BranchData) {
    super.initialise(topBranch);
  }

  async activate(): Promise<boolean> {
    let result: boolean = await super.activate();

    this.configText = (await this.assistEval.evaluate(this.textEval)).getValue() as string;
   
    this.text = this.assistValues.formatText(this.scope, this.configText.replace(/\\\"/g, '"'));

    if (this.valueNames.length > 0 && this.dataValues.length == 0) {

      this.valueNames.forEach(valueName => {

        let dataValue: ValueBase = this.scope.findValue(valueName);

        if (dataValue) {
          this.dataValues.push(dataValue);
        }
      });
    }

    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }

  deactivate(): boolean {

    return super.deactivate();;
  }

  setHighlights(highlight : boolean) {
    this.dataValues.forEach(dataValue =>{
      dataValue.setHighlight(highlight);
    });
  }

}


