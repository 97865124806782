
import { IStepEval } from "../interfaces/istep-eval";
import { EvalNode } from "./eval-node";
import { StepBase } from "./step-base";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { ValueBase } from "./value-base";
import { EnumValueType } from "../enums/enum-value-type";
import { EnumEventType } from "../enums/enum-event-type";
import { EnumValueSource } from "../enums/enum-value-source";
import { Subscription } from "rxjs";

export class StepEvalSet extends StepBase {

  public eval: EvalNodeType;
  public dataValue : ValueBase;
  
  public valueName : string;
  public valueType : EnumValueType;
  public valueNames : string[] = [];

  private _isReturn : boolean = false;
  private _config : IStepEval;

  constructor(
    index: number,
    config: IStepEval,
    defaultName : string
  ) {
    super(index, config);
    //let arrNames: string[] = [];

    this.dataValue = null;
    this.valueName = defaultName;
    this.valueType = EnumValueType.Value_string;

    if (config.hasOwnProperty("valueNames")) {
      this.valueNames = config.valueNames;
    }

    this._config = config;

  }
  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.eval !== undefined) {
      evalNodes.push(this._config.eval);
    }

    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.eval !== undefined) {
      this.eval = evalNodes.splice(0,1)[0];

      if (EvalNode.isEvalNode(this.eval))  {

        let evalNode = this.eval as EvalNode;
  
        if (evalNode.source == EnumValueSource.ValueSource_function && evalNode.name == "returnFunc") {
          this._isReturn = true;
        }
      }  

    }

    this._config = null;

  }

  async activate(): Promise<boolean> {
    let result: boolean = false;

    result = await super.activate();

    if (this.dataValue === null) {

      this.dataValue =  this.scope.findDeclareValue({type:this.valueType, name: this.valueName});

      this.valueNames.forEach(valueName => {

        let dataValue = this.scope.findValue(valueName);

        if (dataValue) {
          this._subscriptions.push (
            dataValue.onChangeValue.subscribe((value) => {
              if (this.active) {
                this.evaluate();
              }  
            })
          );
        }
      });
    }
  
    this.evaluate();

    return result;
  }

  async callStep() {

    await this.evaluate(true);

  }

  private async evaluate(isCall : boolean = false) : Promise<void> {

    let eventType : EnumEventType = isCall ? EnumEventType.stepCallComplete : EnumEventType.stepComplete;
    let eventData = {index: this.index, returnDataValue: null};
    let subscription : Subscription;

    if (!isCall) {
      subscription = this.assistStateService.stepProcessText.subscribe(text => this.processText = text);
    }

    this.dataValue = await this.assistEval.evaluate(this.eval);

    if (!isCall) {
      subscription.unsubscribe();
      this.processText = "";
    }

    if (isCall && this._isReturn) {
      eventData.returnDataValue = this.dataValue;      
    }

    if (this.dataValue.isError) {
      this.processText = "Error: " + this.dataValue.error;
    }

    this.sendEvent(eventType, eventData);   

  }

}

