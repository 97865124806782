import { Component, OnInit, Input, ViewChild, Type, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssistConfig } from '../../classes/assist-config';
import { ValueHostDirective } from '../../directives/value-host.directive';
import { IValueComponent } from '../../interfaces/ivalue-component';
import { ValueBooleanComponent } from '../values/value-boolean.component';
import { ValueNumberComponent } from '../values/value-number.component';
import { ValueStringComponent } from '../values/value-string.component';
import { ValueTableComponent } from '../values/value-table.component';
import { StepValueItem } from '../../classes/step-value-item';
import { EnumValueItemType } from '../../enums/enum-value-item-type';
import { AssistValues } from '../../classes/assist-values';
import { StepBase } from '../../classes/step-base';
import { EnumStepType } from '../../enums/enum-step-type';
import { ValuePanelComponent } from '../values/value-panel.component';
import { ValueChipComponent } from '../values/value-chip.component';
import { AssistStateService } from '../../services/assist-state.service';
import { ViewOptionType } from '../../types/view-option-type';


@Component({
  selector: 'carina-assist-data-view',
  templateUrl: './assist-value-view.component.html'
})
export class AssistDataViewComponent implements OnInit, OnDestroy {

  @Input() assistConfig: AssistConfig;
  @Input() layoutOptions: ViewOptionType;
  @ViewChild(ValueHostDirective, { static: true }) valueHost: ValueHostDirective;

  private assistValues : AssistValues;
  private _subscriptions: Subscription[] = [];

  //public viewTitle : string = "Manuscript Data";

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private assistStateService : AssistStateService
  ) { 
    this.assistValues = AssistValues.getInstance();      
  }

  ngOnInit(): void {

    this._subscriptions.push (
      this.assistStateService.resetWorkflow.subscribe((workflowId) => {
        this.resetWorkflow();
      })
    );

    this._subscriptions.push (
      this.assistValues.onStepCreated.subscribe((stepData) => {
        this.handleStepCreated(stepData);
       })
    );

    this._subscriptions.push (
      this.assistStateService.onLayout.subscribe(options => { 
        this.setLayoutOptions(options);
      })
    );

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setLayoutOptions(options: any) {
    if (options.hasOwnProperty("dataView") && options.dataView.hasOwnProperty("title")) {
      //this.viewTitle = options.dataView.title;
    }
  }  

  resetWorkflow() {
    const viewContainerRef = this.valueHost.viewContainerRef;
    viewContainerRef.clear();
  }  

  handleStepCreated (stepData : StepBase) {

    if (stepData.type == EnumStepType.Value_Item) {

      let stepValueItem : StepValueItem = stepData as StepValueItem;

      // Get the reference to the component host and clear content
      const viewContainerRef = this.valueHost.viewContainerRef;

      let component: Type<IValueComponent> = null;

      switch (stepValueItem.valueItemType) {
        case EnumValueItemType.ValueItem_boolean:
          component = ValueBooleanComponent;
        break;
        case EnumValueItemType.ValueItem_number:
          component = ValueNumberComponent;
        break;
        case EnumValueItemType.ValueItem_string:
          component = ValueStringComponent;
        break;
        case EnumValueItemType.ValueItem_table:
          component = ValueTableComponent;
        break;
        case EnumValueItemType.ValueItem_panel:
          component = ValuePanelComponent;
        break;
        case EnumValueItemType.ValueItem_chip:
          component = ValueChipComponent;
        break;
        case EnumValueItemType.ValueItem_object:
        break;
      }

      if (component) {
        //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

        const componentRef = viewContainerRef.createComponent<IValueComponent>(component);
        componentRef.instance.stepData = stepValueItem;
      }

    }

  }
}
