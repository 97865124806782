
import { EnumEventType } from "../enums/enum-event-type";
import { EventData } from "./event-data";
import { StepBase } from "./step-base";
import { IStepActionStatus } from "../interfaces/istep-action-status";
import { BranchData } from "./step-branch";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { ValueBase } from "./value-base";
import { EnumValueType } from "../enums/enum-value-type";

export class StepActionStatus extends StepBase {

  isValid : boolean = true;
  complete : boolean = false;
  showOnComplete : boolean = false;

  validText: string = "";
  validTextEval: EvalNodeType = null;
  invalidText: string = "";
  invalidTextEval: EvalNodeType = null;

  public statusMessage : string = "";

  actionConfig: any = {};
  valueName : string = "";
  dataValue : ValueBase = null;
  actionConfigEval: EvalNodeType = null;

  private _config : IStepActionStatus;

  constructor(index: number, config: IStepActionStatus) {
    super(index, config);

    this._config = config;
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.validText !== undefined) {
      evalNodes.push(this._config.validText);
    }
    
    if (this._config.invalidText !== undefined) {
      evalNodes.push(this._config.invalidText);
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      evalNodes.push(this._config.actionConfig); 
    } 

    if (this._config.hasOwnProperty("valueName")) {
      this.valueName = this._config.valueName;
    }     
    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.hasOwnProperty("validText")) {
      this.validTextEval = evalNodes.splice(0,1)[0];
    }

    if (this._config.hasOwnProperty("invalidText")) {
      this.invalidTextEval = evalNodes.splice(0,1)[0];
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      this.actionConfigEval = evalNodes.splice(0,1)[0];
    } 

    this._config = null;

  }

  initialise (topBranch : BranchData) {
    super.initialise(topBranch);

    this._subscriptions.push (
      topBranch.branchEvent.subscribe(event => {
        this.handleBranchEvent(event);
      })
    );

    this._subscriptions.push (
      this.assistStateService.onWorkflowValidity.subscribe(async isValid => {
        await this.handleValidityChange(isValid);
      })
    );    
  }

  destroy () {
    
  }

  private async setActionConfig (evalNode : IEvalNodeType) {

    this.actionConfig = {};

    try {
      let configDataValue = await this.assistEval.evaluate(evalNode);
      if (configDataValue.isValue && configDataValue.isObject) {
        this.actionConfig = configDataValue.getValue();

        if (this.actionConfig.hasOwnProperty("onComplete")) {
          this.showOnComplete = this.actionConfig.onComplete;
        }

      }
    }
    catch(err) {
      console.log(err);
    }
  }

  deactivate(): boolean {
    let result : boolean;

    result = super.deactivate();

    return result;
    
  }

  async activate(): Promise<boolean> {
    let result: boolean;
    
    if (this.actionConfigEval != null) {
      await this.setActionConfig(this.actionConfigEval);
    }
    
    result = await super.activate();

    if (this.valueName !== "" && this.dataValue === null) {
      this.dataValue =  this.scope.findDeclareValue({type:EnumValueType.Value_object, name: this.valueName});
    }    


    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }  

  handleBranchEvent(event: EventData) {

    let complete = this.complete;
    if (event.type == EnumEventType.branchComplete) {
      complete = true;

    } else if (event.type == EnumEventType.branchIncomplete) {
      complete = false;
    }

    if (complete !== this.complete) {
      this.complete = complete;
    }
  }

  async handleValidityChange(isValid : boolean) {
    
    this.statusMessage = "";  
    if (isValid && this.validTextEval !== null) {
      this.statusMessage = (await this.assistEval.evaluate(this.validTextEval)).getValue() as string;
    }
    if (!isValid && this.invalidTextEval !== null) {
      this.statusMessage = (await this.assistEval.evaluate(this.invalidTextEval)).getValue() as string;
    }

    if (this.dataValue != null) {
      this.dataValue.setValue(isValid);
    }

    this.isValid = isValid;
  }

  get show() : boolean {

    return (!this.showOnComplete || this.complete);

  }

}

