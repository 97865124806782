import { Component, ViewChild, Output, Input, EventEmitter} from '@angular/core';
import { NgModel } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent} from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';


export class MonthpickerDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string) {
    super(matDateLocale);
  }

  override parse(value: string): Date | null {
    const monthAndYearRegex = /(10|11|12|0\d|\d)\/[\d]{4}/;
    if (value?.match(monthAndYearRegex)) {
      const parts = value.split('/');
      const month = Number(parts[0]);
      const year = Number(parts[1]);
      if (month > 0 && month <= 12) {
        return new Date(year, month - 1);
      }
    }
    return null;
  }

  override format(date: Date, displayFormat: any): string {
    const month = date.getMonth() + 1;
    const monthAsString = ('0' + month).slice(-2);
    const year = date.getFullYear();
    return monthAsString + '/' + year;
  }
}

@Component({
  selector: 'carina-datepicker',
  templateUrl: './carina-datepicker.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class CarinaDataPickerComponent {
  @ViewChild('model', { static: false }) model : NgModel;

  @Input()
  public entryDate: Date | null = null;
  @Input()
  public minDate: Date | null = null;
  @Input()
  public maxDate: Date | null = null;

  @Output()
  public dateChange = new EventEmitter<Date | null>();

  public emitDateChange(event: MatDatepickerInputEvent<Date | null, unknown>): void {

    if (this.model.hasError('matDatepickerMin') || this.model.hasError("matDatepickerMax")) {
      this.dateChange.emit(this.entryDate);
    }
  }

  public monthChanged(value: any, widget: any): void {
    this.entryDate = value;
    widget.close();
    this.dateChange.emit(this.entryDate);
  }
}

