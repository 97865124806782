import { Component, Type, OnInit, Input, ViewChild, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssistConfig } from '../../classes/assist-config';
import { StepHostDirective } from '../../directives/step-host.directive';
import { IStepComponent } from '../../interfaces/istep-component';
import { PromptYNComponent } from '../prompts/prompt-yn.component';
import { PromptEntryComponent } from '../prompts/prompt-entry.component';
import { StepSectionComponent } from '../steps/step-section.component';
import { StepEvalComponent } from '../steps/step-eval.component';
import { StepTextComponent } from '../steps/step-text.component';
import { EnumStepType } from '../../enums/enum-step-type';
import { StepBase } from "../../classes/step-base";
import { AssistValues } from '../../classes/assist-values';
import { PromptMultiComponent } from '../prompts/prompt-multi.component';
import { PromptButtonGroupComponent } from '../prompts/prompt-buttonGroup.component';
import { PromptFlagGroupComponent } from '../prompts/prompt-flagGroup.component';
import { PromptNextComponent } from '../prompts/prompt-next.component';
import { AssistStateService } from '../../services/assist-state.service';
import { PromptButtonComponent } from '../prompts/prompt-button.component';
import { PromptSelectComponent } from '../prompts/prompt-select.component';
import { ViewOptionType } from '../../types/view-option-type';
import { PromptDateRangeComponent } from '../prompts/prompt-dateRange.component';

@Component({
  selector: 'carina-assist-step-view',
  templateUrl: './assist-step-view.component.html'
})
export class AssistStepViewComponent implements OnInit { 
  
  @Input() assistConfig: AssistConfig;
  @Input() layoutOptions: ViewOptionType;
  @ViewChild(StepHostDirective, { static: true }) stepHost: StepHostDirective;
  @ViewChild('scrollBuffer') scrollBuffer:ElementRef;
  
  private assistValues : AssistValues;
  private _subscriptions: Subscription[] = [];
  //public viewTitle : string = "Manuscript Submission Checker";
  
  constructor(
    private assistStateService : AssistStateService
    ) {
      this.assistValues = AssistValues.getInstance();     
    }  
    
  ngOnInit(): void {
      
    this._subscriptions.push (
      this.assistStateService.resetWorkflow.subscribe((workflowId) => {
        this.resetWorkflow();
      })  
    );  
      
    this._subscriptions.push (
      this.assistValues.onStepCreated.subscribe((stepData) => {
        this.handleStepCreated(stepData);
      }) 
    );      

    this._subscriptions.push (
      this.assistStateService.onLayout.subscribe(options => { 
        this.setLayoutOptions(options);
      })
    );

  }  
        
  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }  
        
  setLayoutOptions(options: any) {
    if (options.hasOwnProperty("stepView") && options.stepView.hasOwnProperty("title")) {
      //this.viewTitle = options.stepView.title;
    }
  }

  resetWorkflow() {
    const viewContainerRef = this.stepHost.viewContainerRef;
    viewContainerRef.clear();
  }
      
  handleStepCreated (stepData : StepBase) {

    let component: Type<IStepComponent> = null;  

    switch (stepData.type) {
      case EnumStepType.Prompt_Next:
        component = PromptNextComponent;
      break;  
      case EnumStepType.Prompt_YN:
      case EnumStepType.Prompt_Toggle:
        component = PromptYNComponent;
      break;  
      case EnumStepType.Prompt_Entry:
      case EnumStepType.Prompt_List:
        component = PromptEntryComponent;
      break;  
      case EnumStepType.Prompt_Multi:
      case EnumStepType.Prompt_Single:
        component = PromptMultiComponent;
      break; 
      case EnumStepType.Prompt_Button:
        component = PromptButtonComponent;
      break; 
      case EnumStepType.Prompt_Select:
        component = PromptSelectComponent;
      break; 
      case EnumStepType.Prompt_Radio:
      case EnumStepType.Prompt_Checkbox:
        component = PromptButtonGroupComponent;
      break;        
      case EnumStepType.Prompt_Flags:
        component = PromptFlagGroupComponent;
      break;        
      case EnumStepType.Prompt_Section:
        component = StepSectionComponent;
      break;  
      case EnumStepType.Prompt_Text:
        component = StepTextComponent;
      break;  
      case EnumStepType.Prompt_Date_Range:
        component = PromptDateRangeComponent;
      break;  
      case EnumStepType.Eval_Set:
      case EnumStepType.Eval_Branch:
      case EnumStepType.Eval_Group:
      case EnumStepType.Eval_While:
      case EnumStepType.Eval_Declare:
        component = StepEvalComponent;
      break;  
    }  
        
    if (component) {
      const viewContainerRef = this.stepHost.viewContainerRef;
      //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

      let addIndex = stepData.getParentAddIndex();

      //console.log("AddIndex: " + addIndex + " length = " + viewContainerRef.length);


      if (addIndex > viewContainerRef.length) {
        addIndex = viewContainerRef.length;
      }  

      const componentRef = viewContainerRef.createComponent<IStepComponent>(component, {index:addIndex});

      stepData.addParentStep();

      componentRef.instance.stepData = stepData;

      stepData.setComponent(viewContainerRef, componentRef);

      if (this.scrollBuffer && addIndex + 1 == viewContainerRef.length) {
        window.setTimeout(() => this.scrollBuffer.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" }), 1);
      }
    }  
  }  
}
