import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepBase } from '../../classes/step-base';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  template: '',
  styleUrls: [],
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ]),
      // transition(':leave', [
      //   animate('0.4s', style({ opacity: 0 }))
      // ])
    ]),
  ]
})
export class StepBaseComponent implements OnDestroy {

  protected _subscriptions: Subscription[] = [];
  private scrollTarget:ElementRef;
  public groupOptions = {indent:0};
  public indent : string = "0";

  @ViewChild('scrollTarget') set viewActive(view : ElementRef) {
    if (view) {
      view.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  constructor() { }

  ngOnDestroy() : void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isArray(entry: any) : boolean {
    return Array.isArray(entry);
  }  

  getGroupOptions(step: StepBase) {

    Object.assign(this.groupOptions, step.getGroupOptions());

    if (this.groupOptions.hasOwnProperty("indent")) {
      this.indent = "" + this.groupOptions.indent;
    }

  }

  onAssistUpdate() : void {};

}
