import { Injectable } from '@angular/core';
import { IContextService, IContextMessage, LayoutOptionType } from 'carina-workflow-assist'; 
import { AppService } from './app.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AssistContextService implements IContextService {

  private _action: Subject<IContextMessage> = new Subject();
  public readonly onAction: Observable<IContextMessage> = this._action.asObservable();

  constructor(
    private appService: AppService
  ) { };

  public hasSession = false;

  getBaseUrl(): string {

    return '/';

  }
  
  setPageLayout (strLayout : string) : void {

    this.appService.setPageLayout(strLayout);

  };
  
  setContentLayout (strLayout : string) : void {

    this.appService.setPageLayout(strLayout);

  };
  
  setPageTitle (strTitle : string) : void {
    this.appService.setPageTitle(strTitle);
  };


  setAction (action: string, data? : any) {

    let message : IContextMessage = {action: action, data: {}};

    if (data) {
      message.data = data;
    }  

    this._action.next(message);

  }

  navigate(location: string): void { 
    //this.authService.navigate(location);
  }

  getDefaultLayout() : LayoutOptionType {

    return {
      stepView: {enable: true, flex: 5, title:""},
      dataView : {enable: false},
      actionView :  {enable: true, flex: 1, title:"Generated Statement", layout: [
        {id:"carina-actions", direction:"column", children:[
          {id:"carina-status", flex:"1", children:[]}, 
          {id:"carina-body", flex:"7", direction:"row", children:[
            {id:"carina-main", flex:"0 0 85%", children:[
            
            ]},
            {id:"carina-buttons", flex:"0 0 15%", direction:"column", alignItems:"flex-end", children:[
            
            ]}  
          ]} 
        ]}  
      ]}
    }

  }

}