import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { ValueString } from '../../classes/value-string';
import { EnumValueState } from '../../enums/enum-value-state';
import { ValueBaseComponent } from './value-base.component';

@Component({
  selector: 'carina-value-string',
  templateUrl: './value-string.component.html'
})
export class ValueStringComponent extends ValueBaseComponent  implements OnInit, OnDestroy {

  dataValue: ValueString;
  valueText : string = "";
  contentText : string | string [] = "";
  showOnValue : boolean = false;
  hideOnEmpty : boolean = false;

  strStyle : string = "";

  private _valueState: EnumValueState = EnumValueState.FieldState_Pending;

  constructor() {
    super();  
  }

  ngOnInit(): void {

    super.ngOnInit();

    this.showOnValue = this.stepData.viewConfig.hasOwnProperty("showOnValue") && this.stepData.viewConfig.showOnValue == true;
    this.hideOnEmpty = this.stepData.viewConfig.hasOwnProperty("hideOnEmpty") && this.stepData.viewConfig.hideOnEmpty == true;

    if (this.findDeclaredValue()) {
      this.handleValue();
    }  

    if (this.stepData.style) {
      this.strStyle = this.stepData.style;
    }
  }

  handleValue () {

    let isArray : boolean;
    let strValue : string = "";

    if (this.dataValue.isValue) {
      if (this.dataValue.isArray) {
        let arrValue : string[] = this.dataValue.getValue() as unknown as string[];

        if (arrValue.length > 1) {
          isArray = true;

          this.contentText = arrValue.join('\n');

          this.valueText = arrValue[0];

          if (this.valueText.length > 50) {
            this.valueText = this.valueText.substring(0, 50);
          }
          this.valueText += '\u2026';

        } else {
          if (arrValue.length == 1) {
            strValue =  arrValue[0];
          }
        }
      } else {
        strValue = this.dataValue.getValue() as string;
      }


      if (!isArray) {


        this.valueText = strValue
        if (this.stepData.label !== "" && this.valueText.length > 50) {
          this.contentText = this.valueText;
          this.valueText = this.valueText.substring(0, 50) + '\u2026';
        } else {
          this.contentText = "";
        }
      } 
    }    
  }
  
  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  findDeclaredValue() {

    let result = super.findDeclaredValue();

    if (result && this.dataValue) {

      result = true;

      this._subscriptions.push (
        this.dataValue.onChangeValue.subscribe(value => {
          this.handleValue();
        })
      );
    }

    return result;
  }

  get show(): boolean {
    return (!this.showOnValue || this.isValue) && (!this.hideOnEmpty || this.valueText !== "" || this.contentText !== "");
  }
}