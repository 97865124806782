import { EnumValueType } from "../enums/enum-value-type";
import { IArgDef } from "../interfaces/iarg-def";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";
import { ValueFactory } from "./value-factory";

export class Scope {

  private parentScope: Scope = null;
  private valueMap: Map<any, ValueBase>;

  constructor (parentScope: Scope) {
    this.parentScope = parentScope;
    this.valueMap = new Map();
  }

  clear() {
    this.valueMap.clear();
  }

  hasValue (valueName : string) : boolean {
    return this.valueMap.has(valueName);
  }

  findValue (valueName : string) : ValueBase {
    let result : ValueBase = null;
    let dataValue : ValueBase;

    dataValue = this.findLocalValue(valueName);

    if (dataValue) {
      result = dataValue;
    } else if (this.parentScope !== null) {
      result = this.parentScope.findValue(valueName);
    }

    return result;
  }

  findDeclareValue(config : IDataValue)  : ValueBase  {

    let result : ValueBase = this.findValue(config.name);

    if (result === null) {
      result = this.declareValue(config);
    }

    return result;
  }

  findLocalValue (valueName : string) : ValueBase {
    let result : ValueBase = null;
    let dataValue : ValueBase;

    dataValue = this.valueMap.get(valueName);

    if (dataValue !== undefined) {
      result = dataValue;
    } else {
      result = null;
    }

    return result;
  }

  declareValue (config : IDataValue) : ValueBase {
    
    let result : ValueBase;

    if (!this.hasValue(config.name)) {
      result = ValueFactory.create(config);

      this.valueMap.set(config.name, result);

    } else {  
      result = ValueFactory.createErrorValue(EnumValueType.Value_string, "Value '" + config.name + "' already declared", null);
    }

    return result;
  }  

  getDataValue (valueName : string) : ValueBase {
    
    let result = null;
    let dataValue : ValueBase;

    dataValue = this.findValue(valueName);

    if (dataValue !== null) {
      result = dataValue
    } else {
      result = ValueFactory.createErrorValue(EnumValueType.Value_string, String("Unknown value '" + valueName), null); 
    }

    return result
  }

  callBegin (argDefs: IArgDef[] = [], argValues : any[] = []) {

    this.clear();

    argDefs.forEach((argDef, index) => {

      if (index < argValues.length) {
        this.declareValue({name: argDef.name, type: argDef.type, value: argValues[index]});
      }
    });
  }

  callEnd () {

    this.clear();

  }



}