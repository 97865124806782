import { EnumValueSource } from "../enums/enum-value-source";
import { EnumValueType } from "../enums/enum-value-type";
import { IEvalNode } from "../interfaces/ieval-node";
import { IArgDef } from "../interfaces/iarg-def";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { BranchData } from "./step-branch";
import { Scope } from "./scope";

const configFunc = {
  setFunc: {offset: 1},
  arrayUnshiftFunc: {offset: 1},
  arrayShiftFunc: {offset: 1},
  arrayPushFunc: {offset: 1},
  arrayPopFunc: {offset: 1}
}

export class EvalNode {

  public name: string;
  public scope: Scope;
  public dbgLine : number;
  public dbgOffset : number;
  public source: EnumValueSource;
  public type: EnumValueType;
  public index : number;
  public params: EvalNodeType[] = [];
  public branch?: BranchData;
  public args?: IArgDef[] = [];

  static isEvalNode (evalNode : any) : boolean{
    return evalNode !== null &&
      evalNode.name !== undefined &&
      evalNode.source !== undefined  &&
      evalNode.type !== undefined
  }

  static isProc (evalNode : any) : boolean{
    return EvalNode.isEvalNode(evalNode) && 
      evalNode.source == EnumValueSource.ValueSource_procedure;
  }

  constructor (scope : Scope, config : IEvalNode) {

    this.scope = scope;
    this.source = config.source; 
    this.type = config.type;
    
    if (config.hasOwnProperty("l")) {
      this.dbgLine = config.l;
    }
    if (config.hasOwnProperty("o")) {
      this.dbgOffset = config.o;
    }

    if (config.hasOwnProperty("name")) {
      this.name = config.name;
    }

    if (config.hasOwnProperty("index")) {
      this.index = config.index;
    }

    if (config.hasOwnProperty("args")) {       
      config.args.forEach(argDef => this.args.push(argDef));
    }

  }

  addParamNode (newNode : IEvalNodeType) {

    this.params.push(newNode);

  }

  getValueNames(arrNames : string[]) {

    if (this.source == EnumValueSource.ValueSource_value) {
      arrNames.push(this.name);
    }

    if (Array.isArray(this.params)) {
      
      let checkOffset : number = 0;
      if (this.source == EnumValueSource.ValueSource_function &&
          configFunc.hasOwnProperty(this.name)) {
        checkOffset = configFunc[this.name].offset;    
      }

      this.params.forEach((param, index) => {
        if (index >= checkOffset && typeof param == "object" && EvalNode.isEvalNode(param)) {
          (param as EvalNode).getValueNames(arrNames);
        }
      });
    }
  }


}
