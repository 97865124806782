import { Component, Input, QueryList, Type, ViewChildren, ComponentRef, AfterViewInit} from '@angular/core';
import { StepBase } from '../../classes/step-base';
import { ILayoutComponent } from '../../interfaces/ilayout-component';
import { LayoutType } from '../../types/layout-option-type'
import { LayoutElementComponent } from './layout-element';


@Component({
  selector: 'carina-layout-host',
  templateUrl: 'layout-host.html',
})
export class LayoutHostComponent implements AfterViewInit {

  @Input() layout: LayoutType[];

  @ViewChildren(LayoutElementComponent) elements: QueryList<LayoutElementComponent>;

  constructor() {
    console.log("LayoutHostComponent created");
  }
  
  ngAfterViewInit() {
    this.elements.changes.subscribe((r) => {
      console.log("QueryList<LayoutElementComponent>: " +r.length);
    });
  }  

  createComponent(hostName : string, component : Type<ILayoutComponent>, stepData: StepBase, addIndex : number = -1 ) : ComponentRef<ILayoutComponent> {

    let componentRef : ComponentRef<ILayoutComponent> = null;
    let layoutElement : LayoutElementComponent = null;
    
    this.elements.some(element => {
      layoutElement = element.findElement(hostName)
      return layoutElement !== null;
    });


    if (layoutElement != null) {

      let options : any = {};

      const viewContainerRef = layoutElement.getViewContainerRef();

      if (addIndex >= 0) {

        if (addIndex > viewContainerRef.length) {
          addIndex = viewContainerRef.length;
        }         

        options.index = addIndex;
      }

      componentRef = viewContainerRef.createComponent<ILayoutComponent>(component, options);
             
      componentRef.instance.stepData = stepData;
      
    }

    return componentRef;
  }


  clearComponents() {

    this.elements.forEach(element => {
      element.clearComponents();
    });

  }
}