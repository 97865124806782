import { LayoutType } from "../types/layout-option-type";

export class LayoutElement {

  id: string;
  children : LayoutElement[] = [];
  styleSelf : any = {};
  styleChild : any = {};

  constructor(layout: LayoutType) {

    this.id = layout.id;
    if (layout.hasOwnProperty("direction") || layout.hasOwnProperty("alignItems")) {
      this.styleChild.display = "flex";
      if (layout.hasOwnProperty("direction")) {
        this.styleChild.flexDirection = layout.direction;
      }
      if (layout.hasOwnProperty("alignItems")) {
        this.styleChild.alignItems = layout.alignItems;
      }
    }
    if (layout.hasOwnProperty("flex")) {
      this.styleSelf.flex = layout.flex;
    }    

    layout.children.forEach(child=> this.children.push(new LayoutElement(child)) );

  }

}