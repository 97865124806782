import { EnumEventType } from "../enums/enum-event-type";
import { IStepActionEntry } from "../interfaces/istep-action-entry";
import { StepBase } from "./step-base";
import { ActionElement } from "./action-element";
import { EnumActionElementType } from "../enums/enum-action-element-type.";
import { Observable, Subject } from "rxjs";
import { ValueBase } from "./value-base";
import { BranchData } from "./step-branch";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { ActionDataType } from "../types/action-data-type";
import { ValueFactory } from "./value-factory";
import { EnumValueType } from "../enums/enum-value-type";
import { EnumStepType } from "../enums/enum-step-type";
import { EvalNode } from "./eval-node";
import { EnumValueSource } from "../enums/enum-value-source";
import { Scope } from "./scope";


export class StepActionEntry extends StepBase {

  textEval : EvalNodeType = null;
  contentEval : EvalNodeType = null;
  dataEval : EvalNodeType = null;
  entryCount : number = 1;
  actionData : object = null;
  paramText : string [];
  actionName : string;
  entryName : string = "";
  textEntries : ActionElement [][] = [];
  contentEntries : ActionElement [][] = [];
  highlight : boolean = false;
  valueNames : string[] = [];
  dataValues : ValueBase[] = [];
  private _canProcess : boolean = true;
  valueName : string = "";
  dataValue : ValueBase = null;
  sortIndex : number = 0;

  private _config : IStepActionEntry;

  protected _highlight: Subject<boolean> = new Subject();
  public readonly onChangeHighlight: Observable<boolean> = this._highlight.asObservable();

  constructor(
    index: number,
    config: IStepActionEntry
  ) {
    super(index, config);

    this.actionName = config.actionName;

    if (config.hasOwnProperty("entryName")) {
      this.entryName = config.entryName;
    }

    if (config.hasOwnProperty("valueNames")) {
      this.valueNames = config.valueNames;
    }    

    if (this.type == EnumStepType.Action_Entry_Count) {
      this.entryCount = 0;
      if (config.hasOwnProperty("valueName")) {
        this.valueName = config.valueName;
      } 
    }

    this._config = config;
  }

  get canProcess() : boolean {
    return this._canProcess;
  }

  set canProcess(value : boolean) {

    if (this._canProcess !== value) {
      this._canProcess = value;
      this.sendEvent(EnumEventType.actionEntryUpdated, {});    
    }
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.hasOwnProperty("text")) {
      evalNodes.push(this._config.text);
    }

    if (this._config.hasOwnProperty("content")) {
      evalNodes.push(this._config.content);
    }    

    if (this._config.hasOwnProperty("data")) {
      evalNodes.push(this._config.data);
    }  

    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.hasOwnProperty("text")) {
      this.textEval = evalNodes.splice(0,1)[0];
    }

    if (this._config.hasOwnProperty("content")) {
      this.contentEval = evalNodes.splice(0,1)[0];
    }    

    if (this._config.hasOwnProperty("data")) {
      this.dataEval = evalNodes.splice(0,1)[0];
    }  

    this._config = null;

  }

  initialise (topBranch : BranchData) {
    super.initialise(topBranch);
  }  

  updateEntryCount (inc : boolean, valueNames : string []) {
    if (inc) {
      this.entryCount++;
      valueNames.forEach(valueName => this.valueNames.push(valueName));
    } else {
      this.entryCount--;
      valueNames.forEach(valueName => {
        let index = this.valueNames.indexOf(valueName);
        if (index > 0) {
          this.valueNames.splice(index, 1);
        }
      });
    }

    if (this.dataValue !== null) {
      this.dataValue.setValue(this.entryCount);
    }

    if (valueNames.length) {
      this.highlightSubscribe();
    }
  }

  async createData() : Promise<ValueBase> {

    let dataValue : ValueBase = null;
    let generate : boolean = false;

    if (this.dataEval !== null) {
      if (this.actionData == null) {
        generate = true;
      } else  {
        if (typeof this.dataEval === "object" && EvalNode.isEvalNode(this.dataEval)) {

          let dataNode : EvalNode = this.dataEval as EvalNode;
          
          if (dataNode.source == EnumValueSource.ValueSource_value && dataNode.type == EnumValueType.Value_object) {
            generate = true;
          }
        }
      }  
    } else {
      dataValue = ValueFactory.createErrorValue(EnumValueType.Value_object, "No value data for action entry", null);
    }   

    if (generate) {
      dataValue = await this.assistEval.evaluate(this.dataEval);
      
      if (dataValue.isValue && dataValue.isObject) {
        this.actionData = dataValue.getValue() as object;
        
        if (this.actionData.hasOwnProperty("_order") && typeof this.actionData["_order"] === "number") {
          this.sortIndex = this.actionData["_order"] 
          this.sendEvent(EnumEventType.actionEntrySort, {});
        } else {
          if (this.sortIndex !== 0) {
            this.sortIndex = 0;
            this.sendEvent(EnumEventType.actionEntrySort, {});
          }
        }
        
      } else {
        dataValue = ValueFactory.createErrorValue(EnumValueType.Value_object, "Invlid data type for action entry data", null);
      }
    }

    return dataValue;
  }

  async createEntries(scope: Scope, evalNode: EvalNodeType, entries: ActionElement [][] ) {
    let matches = null;
    let arrTextLines : string[];
    let generate : boolean = false;

    if (evalNode !== null) {
      if (entries.length == 0) {
        generate = true;
      } else  {
        if (typeof evalNode === "object" && EvalNode.isEvalNode(evalNode)) {

          let entryNode : EvalNode = evalNode as EvalNode;
          
          if (entryNode.source == EnumValueSource.ValueSource_value && entryNode.type == EnumValueType.Value_object) {
            generate = true;
          }
        }
      }  
    }

    if (generate) {

      // remove what was there before
      if (entries.length > 0) {
        entries.forEach(entry => {
          entry.forEach(element => element.destroy());
        })
        entries = [];
      }

      let dataValue : ValueBase = await this.assistEval.evaluate(evalNode);
      
      if (dataValue.isValue && dataValue.isArray) {
        arrTextLines = dataValue.getValue() as string[];
      } else {
        arrTextLines.push("Error creating entries: " + dataValue.error);
      }

      let fieldRegex = /\{\s*([a-zA-Z_\d\.]*)\s*(\|\s*([a-zA-Z0-9\-\[\]><}{_)('",.: ]*))?\}/;
    
      arrTextLines.forEach(entry => {
        
        entry = entry.replace(/\\\"/g, '"');

        let elements : ActionElement [] = [];

        let offset = 0;
        do { 

          let str = entry.substring(offset);

          matches = str.match(fieldRegex);

          if (matches) {
            let valueName = matches[1];
            let path = valueName.split(".");

            if (path.length > 1) {
              valueName = path.shift(); 
            }  

            if (matches.index > 0) {
              elements.push(new ActionElement(this, scope, EnumActionElementType.ActionElement_fixedText, str.substring(0, matches.index)));
            }

            elements.push(new ActionElement(this, scope, EnumActionElementType.ActionElement_value, matches[0], valueName));

            offset += matches.index + matches[0].length;

          } else {
            elements.push(new ActionElement(this, scope, EnumActionElementType.ActionElement_fixedText, str));
          }

        } while (matches != null && offset < entry.length);

        entries.push(elements);

      });
    }

    return entries;
  }

  destroy () {
    super.destroy();

    this.textEntries.forEach(entry => {
      entry.forEach(element => element.destroy());
    })
    this.contentEntries.forEach(entry => {
      entry.forEach(element => element.destroy());
    })

  }  

  highlightSubscribe () {

    this._subscriptions.forEach(subscription => subscription.unsubscribe());

    this.valueNames.forEach(valueName => {

      let dataValue = this.scope.findValue(valueName);

      if (dataValue) {

        this.dataValues.push(dataValue);
        this._subscriptions.push (
          dataValue.onChangeHighlight.subscribe((highlight) => {
            if (this.active) {
              this.handleHighlight();
            }  
          })
        );
      }
    });

  }

  async activate(): Promise<boolean> {
    let result: boolean;

    result = await super.activate();

    if (this.valueName !== "" && this.dataValue === null) {
      this.dataValue =  this.scope.findDeclareValue({type:EnumValueType.Value_number, name: this.valueName});
    }

    //if (this.textEntries.length == 0) {
    this.textEntries = await this.createEntries(this.scope, this.textEval, this.textEntries);
    this.contentEntries = await this.createEntries(this.scope, this.contentEval, this.contentEntries);
    await this.createData();  
    //}

    if (this.dataValues.length == 0 && this.valueNames.length > 0) {
      this.highlightSubscribe();
    }

    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }

  deactivate(): boolean {
    let result: boolean;

    result = super.deactivate();

    this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });

    return result;
  }
  
  handleHighlight() {

    let isHighlighted = this.dataValues.some(dataValue => {
      return dataValue.isHighlighted;
    });

    if (isHighlighted !== this.highlight) {
      this.highlight = isHighlighted;
      this._highlight.next(this.highlight);
    }

  }

  getEntryLines (entries: ActionElement [][]) {

    let lines : string[] = [];

    entries.forEach(elements => {

      let text = "";  

      elements.forEach((element : ActionElement) => {
        text += element.text;        
      });

      lines.push(text);

    });
    
    return lines;
  }


  getActionData (actionData : ActionDataType) {
    
    let lines : string[] = [];

    this.textEntries.forEach(elements => {

      let text = "";  

      elements.forEach((element : ActionElement) => {
        text += element.text;        
      });

      lines.push(text);

    });

    actionData.text.push(this.getEntryLines(this.textEntries).join('\n'));
    actionData.content.push(this.getEntryLines(this.contentEntries).join('\n'));
    actionData.data.push(this.actionData);

  }

}