
<div [attr.carina-indent]="indent">
  <div #scrollTarget *ngIf="stepData.active" class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div class="stepIndentLeft">
    </div>
    <div class="stepIndentRight">
    </div>
    <div class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div class="stepButtons">
    </div>
  </div>
  <div [@showHide] *ngIf="stepData.active && stepData.prompt!=''" class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div class="stepIndentLeft">
    </div>
    <div class="stepIndentRight">
    </div>
    <div class="stepText stepData">
      <p>{{stepData.prompt}}</p>
    </div>
    <div class="stepButtons">
    </div>
  </div>
  <div [@showHide] *ngIf="stepData.active" class="assistStep">
    <div class="stepOffset">
      <mat-hint class="mat-hint mat-caption" *ngIf="!isValid">Required entry</mat-hint>
    </div>
    <div class="stepIndentLeft">
    </div>
    <div class="stepIndentRight">
    </div>
    <div class="stepText">
      <mat-form-field class="assistEntry" appearance="outline" [color]="borderColor" [pristine]="isPristine" [ngClass]="{'mat-form-field-invalid': !isValid, 'carina-pristine': stepData.isPristine}" >
        <input *ngIf="!isTextarea" matInput [name]="name" [type]="isPassword && bHidePassword?'password':'text'" [placeholder]="stepData.placeholder" [(ngModel)]="stepData.value" (input)="change()" (focus)="onFocus()" (blur)="onBlur()" (keyup.enter)="process('next')">
        <mat-icon *ngIf="isPassword" matSuffix (click)="bHidePassword = !bHidePassword">{{bHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <textarea *ngIf="isTextarea" matInput [name]="name" [placeholder]="stepData.placeholder" [(ngModel)]="stepData.value" (input)="change()" rows="{{rows}}"  (focus)="onFocus()" (blur)="onBlur()" ></textarea>
      </mat-form-field> 
    </div>
    <div class="stepButtons">
      <div class="stepButtonEnd">
        <mat-button-toggle-group *ngIf="!stepData.stepCompleted" name="Next" aria-label="Next">
          <mat-button-toggle *ngIf="!hideButton" class="mat-button" (click)="process('next')">Next</mat-button-toggle>
        </mat-button-toggle-group>
      </div>  
    </div>  
  </div> 
</div>