<div class='assistPage'>
  <div [class]='contentClass'>
    <div *ngIf="stepViewOptions.enable" class='assistColumn' [style.flex]="stepViewOptions.flex">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">        
            <carina-assist-step-view [layoutOptions]="stepViewOptions" [assistConfig]="config"></carina-assist-step-view>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dataViewOptions.enable" class='assistColumn' [style.flex]="dataViewOptions.flex">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">          
            <carina-assist-data-view [layoutOptions]="dataViewOptions" [assistConfig]="config"></carina-assist-data-view>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="actionViewOptions.enable" class='assistColumn' [style.flex]="actionViewOptions.flex">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">    
            <carina-assist-action-view [layoutOptions]="actionViewOptions" [assistConfig]="config" [actionType]="'statement'"></carina-assist-action-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
