<ng-container *ngIf="stepData.active" >
  <div  class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div #scrollTarget  class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div #scrollTarget class="stepButtons">
    </div>
  </div>
  <div class="assistStep" >
    <div class="stepOffset">
      <mat-hint class="mat-hint mat-caption" *ngIf="!isValid">Required entry</mat-hint>      
    </div>
    <div class="stepDateRange">
      <span class="dateLabel">From:</span>
      <carina-datepicker [maxDate]="toDate" [entryDate]="fromDate" (dateChange)="fromDateChange($event)"></carina-datepicker> 
      <span class="dateLabel">To:</span>
      <carina-datepicker [minDate]="fromDate" [entryDate]="toDate" (dateChange)="toDateChange($event)"></carina-datepicker>        
    </div>
    <div class="stepButtons">
      <div class="stepButtonEnd">
        <mat-button-toggle-group *ngIf="!stepData.stepCompleted" name="Next" aria-label="Next">
          <mat-button-toggle class="mat-button" (click)="process()">Next</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>    
</ng-container>