import { Component, Input, QueryList, ViewChild, ViewChildren, ViewContainerRef, AfterViewInit} from '@angular/core';
import { LayoutElement } from '../../classes/layout-element';
import { LayoutDirective } from '../../directives/layout-element.directive';

@Component({
  selector: 'carina-layout-element',
  templateUrl: 'layout-element.html',
})

export class LayoutElementComponent implements AfterViewInit{

  @Input() layout: LayoutElement;
  @ViewChild(LayoutDirective, { static: false }) layoutRef: LayoutDirective;
  @ViewChildren(LayoutElementComponent) elements: QueryList<LayoutElementComponent>;

  public layoutStyle : any = {};

  constructor() {
    console.log("LayoutElementComponent created: ");
  }

  ngAfterViewInit() {
    this.elements.changes.subscribe((r) => {
      console.log("QueryList<LayoutElementComponent>: " +r.length);
    });
  }
  
  getViewContainerRef () : ViewContainerRef {
    return this.layoutRef.viewContainerRef;
  }

  findElement (id: string) : LayoutElementComponent {

    let result : LayoutElementComponent = null;

    if (id == this.layout.id) {
      result = this;
    } else {

      this.elements.some(element => {
        result = element.findElement(id)
        return result !== null;
      })
    }

    return result;
  }  


  clearComponents() {

    this.layoutRef.viewContainerRef.clear();

    this.elements.forEach(element => {
      element.clearComponents();
    });

  }

}