import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderButton } from '../classes/header-button';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  private _pageLayout: BehaviorSubject<string> = new BehaviorSubject(null);
  public readonly pageLayout: Observable<string> = this._pageLayout.asObservable();

  private _pageTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  public readonly pageTitle: Observable<string> = this._pageTitle.asObservable();

  private _inProgress: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public readonly inProgress: Observable<boolean> = this._inProgress.asObservable();

  private _controls: BehaviorSubject<[]> = new BehaviorSubject(null);
  public readonly controls: Observable <[]> = this._controls.asObservable();

  private _headerButtons: BehaviorSubject<HeaderButton[]> = new BehaviorSubject(null);
  public readonly headerButtons: Observable<HeaderButton[]> = this._headerButtons.asObservable();

  private _headerButtonPress: Subject<HeaderButton> = new Subject();
  public readonly onHeaderButtonsPress: Observable<HeaderButton> = this._headerButtonPress.asObservable();

  constructor(
    private router: Router 
  ) {

    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        // Delete if not used
      }
    });   

    this._pageTitle.next("");
    this._pageLayout.next("default");    
    this._inProgress.next(false);
    
  }

  setPageTitle (strTitle : string)  {
    this._pageTitle.next(strTitle);
  } 

  setPageLayout (strLayout : string)  {
    this._pageLayout.next(strLayout);
  } 

  setInProgress (flag : boolean)  {
    this._inProgress.next(flag);
  } 

  setControls(controls: []) {
    this._controls.next(controls);
  } 

  setHeaderButtons(buttons: HeaderButton[]) {
    this._headerButtons.next(buttons);
  } 
  
  headerButtonPress(button: HeaderButton) {
    this._headerButtonPress.next(button);
  }


}
