
<ng-container *ngIf="actionType=='action' && stepAction.active && stepAction.entryCount > 0"  > 
  <mat-expansion-panel [hideToggle]="stepAction.contentEntries.length==0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div [class.actionHighlight] = "isHighlight" class="actionSection">
          <mat-checkbox *ngIf="allowCancel" class="actionCheckbox" [(ngModel)]="stepAction.canProcess" color="primary" (click)="$event.stopPropagation()">
          </mat-checkbox>
          <div *ngFor="let elements of stepAction.textEntries" [class.actionDisabled] = "!stepAction.canProcess"> 
            <carina-action-element *ngFor="let element of elements" [actionElement]="element"></carina-action-element>
          </div>
        </div> 
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let elements of stepAction.contentEntries" class="actionContent"> 
      <carina-action-element *ngFor="let element of elements" [actionElement]="element"></carina-action-element>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-container *ngIf="actionType=='statement' && stepAction.active && stepAction.entryCount > 0">
  <div *ngFor="let elements of stepAction.textEntries" [class.actionDisabled] = "!stepAction.canProcess" class="assistStatement"> 
    <carina-action-element *ngFor="let element of elements" [actionElement]="element"></carina-action-element>
  </div>
</ng-container> 