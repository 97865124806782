import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { AssistValues } from '../../classes/assist-values';
import { StepValueItem } from '../../classes/step-value-item';
import { ValueNumber } from '../../classes/value-number';
import { IValueComponent } from '../../interfaces/ivalue-component';

@Component({
  selector: 'carina-value-number',
  templateUrl: './value-number.component.html'
})
export class ValueNumberComponent implements OnInit, OnDestroy, IValueComponent {

  @Input() stepData: StepValueItem;
  dataValue: ValueNumber;
  value : number;

  private assistValues: AssistValues;
  private _subscriptions: Subscription[] = [];

  constructor() { 
    this.assistValues = AssistValues.getInstance();

  }

  ngOnInit(): void {

    if (!this.findDeclaredValue()) {

      let subscription : Subscription = 
        this.assistValues.onValueDeclared.subscribe(valueName => {
          if (valueName == this.stepData.valueName) {
            this.findDeclaredValue();
            subscription.unsubscribe();
          }
        });
      this._subscriptions.push(subscription);
    } 
  }

  findDeclaredValue() {

    let result = false;

    this.dataValue = this.stepData.scope.findValue(this.stepData.valueName) as ValueNumber;

    if (this.dataValue) {

      result = true;

      this._subscriptions.push(
        this.dataValue.onChangeValue.subscribe(value => {
          this.value  = value as number;
        })
      );
      
    }

    return result;
  }


  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}