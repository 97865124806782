import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { StepActionStatus } from '../../classes/step-action-status';
import { IActionComponent } from '../../interfaces/iaction-component';
import { AssistStateService } from '../../services/assist-state.service';

@Component({
  selector: 'carina-action-status',
  templateUrl: './action-status.component.html'
})
export class ActionStatusComponent implements  IActionComponent {

  @Input() stepData: StepActionStatus;
  actionType: string = "statement";

  private _subscriptions : Subscription [] = [];

  constructor(
    private assistStateService: AssistStateService
  ) { }

}
