import { EnumStepType } from '../enums/enum-step-type';
import { EnumEventType } from '../enums/enum-event-type';
import { IStepPrompt } from '../interfaces/istep-prompt';
import { StepBase } from "./step-base";
import { ValueBase } from './value-base';
import { EnumValueType } from '../enums/enum-value-type';
import { EnumValueState } from '../enums/enum-value-state';
import { EvalNodeType, IEvalNodeType } from '../types/eval-type';


export class StepPrompt extends StepBase {

  public valueName: string;
  public valueType: EnumValueType;
  public text: string [];
  public configText: string [];
  public textEval: EvalNodeType;
  public prompt: string;
  public placeholder: string;
  public value: string | string[];
  public valid: boolean;
  public stepCompleted: boolean;
  public dataValue : ValueBase;
  public hidden: boolean;
  public canOverride: boolean;
  public outputText: string;
  public resultText: any;
  public errorText: string;
  public overrideText: string;
  public isOverriden = false;
  public textDataValues : ValueBase[] = null; 
  public incompleteOnUpdate : boolean = true;

  public icon : string = "";

  protected _config : IStepPrompt;

  constructor(
    index: number,
    config: IStepPrompt,
    defaultName : string
  ) {
    super(index, config);

    this.valueName = "";
    this.textEval = null;
    this.text = [""];
    this.configText = [""];
    this.prompt = "";
    this.placeholder = "";
    this.active = false;
    this.valid = false;
    this.stepCompleted = false;
    this.dataValue = null;
    this.value = "";
    this.hidden = false;
    this.canOverride = false;
    this.outputText = "";
    this.resultText = {};
    this.errorText = "An error occurred {error}";
    this.overrideText = "Overridden";
    this.isOverriden = false;
    this.promptConfig = {};

    if (config.hasOwnProperty("valueName")) {
      this.valueName = config.valueName;
    } else {
      this.valueName = defaultName;
    }
    if (config.hasOwnProperty("value")) {
      this.value = config.value;
    }

    this.dataValue = null;

    this.valueType = EnumValueType.Value_string;

    switch (this.type) {
      case EnumStepType.Prompt_YN:
      case EnumStepType.Prompt_Toggle:
      case EnumStepType.Prompt_Select:
        this.valueType = EnumValueType.Value_boolean;
      break;  
      case EnumStepType.Prompt_Entry:
        this.valueType = EnumValueType.Value_string;
      break;  
      case EnumStepType.Prompt_List:
        this.valueType = EnumValueType.Value_object;
      break;  
    }     

    if (config.hasOwnProperty("valid")) {
      this.valid = config.valid;
    }
    if (config.hasOwnProperty("prompt")) {
      this.prompt = config.prompt;
    }
    if (config.hasOwnProperty("placeholder")) {
      this.placeholder = config.placeholder;
    }

    if (config.hasOwnProperty("hidden")) {
      this.hidden = config.hidden;
    }

    if (config.hasOwnProperty("icon")) {
      this.icon = config.icon;
    } 

    this.setPromptConfig(config);

    this._config = config;     
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.text !== undefined) {
      evalNodes.push(this._config.text);
    }
    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.text !== undefined) {
      this.textEval = evalNodes.splice(0,1)[0];
    }
    this._config = null;

  }

  async activate(): Promise<boolean> {

    let textValueNames : string[] = null;
    if (!this.created) {
      textValueNames = [];
    }

    let result: boolean = await super.activate();

    let valueData : ValueBase = await this.assistEval.evaluate(this.textEval);

    if (valueData.isValue) {
      if (valueData.isArray) {
        this.configText = valueData.getValue() as string[];
      } else {
        this.configText = [valueData.getValue() as string];
      }
    } else {
      this.configText = ["Error creating prompt text: " +valueData.error];
    }

    this.text = [];
    this.configText.forEach(entry => this.text.push(this.assistValues.formatText(this.scope, entry.replace(/\\\"/g, '"'), textValueNames))); 

    if (textValueNames !== null && textValueNames.length) {
      textValueNames.forEach(textValueName => {
        let dataValue : ValueBase = this.scope.findValue(textValueName);
        if (dataValue !== null) {
          this._subscriptions.push(dataValue.onChangeValue.subscribe(value=> { 
            this.text = [];
            this.configText.forEach(entry => this.text.push(this.assistValues.formatText(this.scope, entry.replace(/\\\"/g, '"'), textValueNames))); 
            this.updateValue();
          }))    
        } 
      });
    }

    if (this.dataValue == null) {

      let valueConfig = {type:this.valueType, name: this.valueName};

      if (this.type == EnumStepType.Prompt_List || 
          this.type == EnumStepType.Prompt_Multi || 
          this.type == EnumStepType.Prompt_Single || 
          this.type == EnumStepType.Prompt_Button || 
          this.type == EnumStepType.Prompt_Radio || 
          this.type == EnumStepType.Prompt_Checkbox || 
          this.type == EnumStepType.Prompt_Flags) {
        valueConfig["value"] = [];
      }

      this.dataValue = this.scope.findDeclareValue(valueConfig);

      if (this.type == EnumStepType.Prompt_Entry) {
        this.value = this.dataValue.getValue() as string;
      } 

    }

    result = await super.activate();    

    if (this.type == EnumStepType.Prompt_Toggle) {
      if (this.dataValue.isUndefined) {
        this.dataValue.setValue(false);
      }
      this.value = this.dataValue.getValue() as boolean ? "yes": "no";
      this.sendEvent(EnumEventType.stepComplete, { index: this.index });    
    } else if (this.type == EnumStepType.Prompt_YN || 
        this.type == EnumStepType.Prompt_Next || 
        this.type == EnumStepType.Prompt_Multi || 
        this.type == EnumStepType.Prompt_Single ||  
        this.type == EnumStepType.Prompt_Button || 
        this.type == EnumStepType.Prompt_Radio || 
        this.type == EnumStepType.Prompt_Checkbox || 
        this.type == EnumStepType.Prompt_Flags) {
      this.stepCompleted = false;
      this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });            
    } else if (this.type == EnumStepType.Prompt_Select) {
      this.stepCompleted = true;
      this.sendEvent(EnumEventType.stepComplete, { index: this.index }); 
    } else if (this.type == EnumStepType.Prompt_Entry || 
      this.type == EnumStepType.Prompt_List) {

      if (this.promptConfig.hasOwnProperty("hideButton") && this.promptConfig.hideButton === true) {
        this.stepCompleted = true;
        this.sendEvent(EnumEventType.stepComplete, { index: this.index }); 
        this.incompleteOnUpdate = false;
      } else {
        this.stepCompleted = false;
        this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });        
      }
    }
  
    return result;
  }

  deactivate(): boolean {
    let result: boolean = false;

    result = super.deactivate();

    if (this.type == EnumStepType.Prompt_YN) {
      this.value = "";
      if (this.dataValue) {
        this.dataValue.setState(EnumValueState.FieldState_Undefined);
      }
    } else if (this.type == EnumStepType.Prompt_Select) {
      this.stepCompleted = false;
      this.value = "no";
      if (this.dataValue && this.dataValue.isValue) {
        if (this.dataValue.getValue() as boolean) {
          this.value = 'yes';
        }
      }
    } else if (this.type == EnumStepType.Prompt_Toggle) {
      if (this.dataValue && this.dataValue.isUndefined) {
        this.dataValue.setValue(false);
      }    
    } else if (this.type == EnumStepType.Prompt_Next || 
               this.type == EnumStepType.Prompt_Entry || 
               this.type == EnumStepType.Prompt_List || 
               this.type == EnumStepType.Prompt_Multi || 
               this.type == EnumStepType.Prompt_Single || 
               this.type == EnumStepType.Prompt_Button || 
               this.type == EnumStepType.Prompt_Radio || 
               this.type == EnumStepType.Prompt_Checkbox || 
               this.type == EnumStepType.Prompt_Flags) {
      this.stepCompleted = false;
    }
 
    return result;
  }

  updateValue() {

    if (this.dataValue !== null) {
      if (this.type == EnumStepType.Prompt_YN || this.type == EnumStepType.Prompt_Select) {
        this.dataValue.setValue(this.value === "yes");
      } else if (this.type == EnumStepType.Prompt_List) {

        let strValue = this.value as string;
        let arrValue = strValue.split(',');

        arrValue = arrValue.map(str => str.trim());

        if (arrValue.length > 0 && arrValue[arrValue.length -1] == "") {
          arrValue.pop();
        }

        this.dataValue.setValue(arrValue);
      } else {
        this.dataValue.setValue(this.value);
      }

      if ((this.type == EnumStepType.Prompt_Next || this.type == EnumStepType.Prompt_Entry || this.type == EnumStepType.Prompt_List) &&
          this.stepCompleted == true) {

        if (this.incompleteOnUpdate) {
          this.stepCompleted = false;
          this.sendEvent(EnumEventType.stepIncomplete, { index: this.index });            
        }    
      }

      this.isPristine = false;
      this.setStatus();
    }
  }

  processChoice(choice: string) {

    this.assistStateService.checkSession();

    let notSet : boolean = this.dataValue.state != EnumValueState.FieldState_Value;
    this.value = choice;
    let currentValue : boolean  = Boolean(this.dataValue.getValue()); 
    let newValue : boolean  = this.value == 'yes'; 

    if (notSet || currentValue !== newValue) {
      this.dataValue.setValue(newValue);
    }

    if (notSet) {
      this.sendEvent(EnumEventType.stepComplete, { index: this.index });    
    }
  }


  processNext() {

    this.assistStateService.checkSession();

    this.isPristine = false;
    this.setStatus();

    this.stepCompleted = true;
    this.sendEvent(EnumEventType.stepComplete, { index: this.index });
    

  }

  setHighlights(highlight : boolean) {
    if (this.dataValue !== null) {
      this.dataValue.setHighlight(highlight);
    };
  }  

  setStatus() {
    if (this.isRequired) {

      let oldValid : boolean = this.isValid;
      if (this.value.length == 0 && !this.isPristine) {
        this.isValid = false;
      } else {        
        this.isValid = true;
      }      

      if (oldValid != this.isValid) {
        this.assistStateService.stepValidityChange(this.isValid);
      }
  
    }
  }
  
}


