import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YesNoPipe } from './yes-no.pipe';
import { NbspOnEmptyPipe } from './nbsp-on-empty.pipe';



@NgModule({
  declarations: [
    YesNoPipe,
    NbspOnEmptyPipe
  ],
  imports: [
    CommonModule
  ], 
  exports: [
    YesNoPipe,
    NbspOnEmptyPipe
  ]
})
export class PipesModule { }
