import { Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IMsgBoxData, IMsgBoxButton } from '../../interfaces/imsg-box-data';


@Component({
  selector: 'carina-msg-box',
  templateUrl: 'dialog-msg-box.html',
})
export class CarinaMsgBox {
  constructor(
    public dialogRef: MatDialogRef<CarinaMsgBox>,
    @Inject(MAT_DIALOG_DATA) public data: IMsgBoxData
  ) {}
}