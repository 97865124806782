import { Component, OnInit, Input } from '@angular/core';
import { StepPrompt} from '../../classes/step-prompt';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-sl',
  templateUrl: './prompt-select.component.html'
})
export class PromptSelectComponent  extends StepBaseComponent implements OnInit, IStepComponent {

  selected : string = 'no';
  isSelected : boolean = false;
  @Input() stepData: StepPrompt;

  constructor() {   
    super(); 
  }

  ngOnInit(): void {
    this.getGroupOptions(this.stepData);
  }

  select() {
    if (this.isSelected) {
      this.isSelected = false;
      this.selected = 'no';
    } else {
      this.isSelected = true;
    }

    this.stepData.value = this.selected;
    this.stepData.updateValue();
    this.stepData.processNext();

    console.log("selected" + this.selected);

  }

}