import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionElement } from '../../classes/action-element';


@Component({
  selector: 'carina-action-element',
  templateUrl: './action-element.component.html'
})
export class ActionElementComponent implements OnInit, OnDestroy {

  @Input() actionElement: ActionElement;
  private _highlight : boolean;

  protected _subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.actionElement !== null) {
      this._subscriptions.push(this.actionElement.onChangeHighlight.subscribe(highlight =>{
        this._highlight = highlight;
      }))
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get isHighlight() : boolean {
    return this._highlight;
  }

} 