import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';

@Component({
  selector: 'carina-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss']
})
export class ControlBarComponent implements OnInit {

  controls : [];

  constructor(
    appService: AppService
  ) {
    appService.controls.subscribe(controls => {
      this.controls = controls;
    })   
  }

  ngOnInit(): void {

 


  }

}
