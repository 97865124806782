import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";

export class ValueString extends ValueBase {
  value : string;

  constructor(config: IDataValue) {
    super(config);
    this.value = "";

    if (config.hasOwnProperty("value")) {
      this.setValue(String(config.value));
    }
  }  

  reset () {
    super.reset();
    this.value = "";
  }

  cast(newType : EnumValueType) : boolean | string | number {

    let result : boolean | string | number = this.value;

    if (newType == EnumValueType.Value_number) {
      result = parseFloat(result);
      if (isNaN(result)) {
        result = 0;
      }
    } else if (newType == EnumValueType.Value_boolean) {
      result = result === 'true';
    }

    return result;
  }   

  getValue(): string {
    return this.value;
  }

  setValue(value: string): void {
    this.value = value;
    super.setValue(value);
    this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {

    this.value = newValue.cast(this.type) as string;
    super.assignValue(newValue);

  }  
}
