import { Component, OnInit, Input, AfterViewChecked} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { StepPromptMulti } from '../../classes/step-prompt-multi';
import { ValueObject } from '../../classes/value-object';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component'

@Component({
  selector: 'carina-step-mu',
  templateUrl: './prompt-multi.component.html'
})
export class PromptMultiComponent extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPromptMulti;

  isSingleSelect : boolean = false;
  isButton : boolean = false;
  selected : string[] = [];
  checked : boolean[] = [];

  strSelected : string = "";
  
  dataValue : ValueObject;
  updateScroll : boolean = false;
  hasNext : boolean = true;
  entryStyle : object = null;

  constructor( ) {
    super();
  }

  ngOnInit(): void {

    this.checked.fill(false, 0, this.stepData.arrEntryValue.length - 1);

    if (this.stepData.type == EnumStepType.Prompt_Single) {
      if (this.stepData.promptConfig.hasOwnProperty("hasNext") && !this.stepData.promptConfig.hasNext) {
        this.hasNext = false;  
      }  
      if (this.stepData.promptConfig.hasOwnProperty("justify") && this.stepData.promptConfig.justify == "right") {
        this.entryStyle = {justifyContent:"flex-end"};  
      }  
    } else if (this.stepData.type == EnumStepType.Prompt_Checkbox || this.stepData.type == EnumStepType.Prompt_Radio || this.stepData.type == EnumStepType.Prompt_Button) {
      this.hasNext = false; 
    }

    this.dataValue = this.stepData.dataValue as ValueObject;

    if (this.stepData.type == EnumStepType.Prompt_Single || 
      this.stepData.type == EnumStepType.Prompt_Button || 
      this.stepData.type == EnumStepType.Prompt_Radio) {
      this.isSingleSelect = true;
    }
    if (this.stepData.type == EnumStepType.Prompt_Button) {
      this.isButton = true;
    }

    if (this.isSingleSelect && !this.isButton) {
      let value : string = this.dataValue.getValue() as unknown as string;

      if (value !== "") {
           
        let index = this.stepData.arrEntryValue.indexOf(value);
        
        if (index >= 0) {
          this.checked[index] = true;
          this.selected[index] = value;
        }
      }

    } else {
      
    }

  }

  select(index: number) {

    if (this.isButton) {

      this.stepData.value = this.stepData.arrEntryValue[index];
      this.process();

    } else {

      if (this.checked[index]) {
        delete this.selected[index];
        this.checked[index] = false;
      } else {
  
        if (this.isSingleSelect) { 
          this.checked.forEach((val, idx, arr) =>{
            arr[idx] = false;
          });
        }
  
        this.checked[index] = true;
  
        if (this.isSingleSelect) {
          this.selected.forEach((val, idx, arr) => {
            if (idx != index) {
              delete arr[idx];
            }
          })
        }
      } 
      
      let arrSel = [];
      let str = "";
      let sep = "";
  
      this.selected.forEach((entry, idx, arr) => {
        if (this.stepData.arrEntryData) {
          arrSel.push(this.stepData.arrEntryData[idx]);
        } else {
          arrSel.push(this.stepData.arrEntryValue[idx]);
        }
        str += sep + entry;
        if (sep == "") {
          sep = ", ";
        }
      });
  
      if (this.isSingleSelect) {
        if (arrSel.length > 0) {
          this.stepData.value = arrSel[0];
        } else {
          this.stepData.value = "";
        }
  
      } else {
        this.stepData.value = arrSel;
      }  
  
      //this.stepData.updateValue();
  
      this.strSelected = str;
  
      if (!this.hasNext) {
        this.process();
      }
    }
  }

  process() {
    this.stepData.updateValue();
    this.stepData.processNext();
  }

  update() {
    this.stepData.processUpdate(); 
    this.updateScroll = true;  
  }

  onAssistUpdate() {
    if (this.isSingleSelect) {
      if (this.dataValue.isUndefined) {
        this.checked = [];
        this.selected = [];        
      }
    }  
  }

  onToggleGroupChange(event: MatButtonToggleChange) {
    const { value } = event;

    let selectedValue = Array.isArray(event.value) ? event.value : [event.value];
    this.selected = [];

    selectedValue.forEach(value => {

      let index = this.stepData.arrEntryValue.indexOf(value);
      
      if (index > -1) {
        this.selected[index] = value;
      }
    })
  }
}
