<mat-form-field appearance="outline">
  <input matInput #model="ngModel"
                  [(ngModel)]="entryDate"
                  [matDatepicker]="picker"
                  (dateChange)="emitDateChange($event)"
                  [min]="minDate"
                  [max]="maxDate" />
  <mat-datepicker-toggle
    matSuffix
    [for]="picker">
  </mat-datepicker-toggle>
  <mat-datepicker #picker
                  [startView]="'multi-year'"
                  (monthSelected)="monthChanged($event, picker)"
                  panelClass="carina-datepicker-month" >
  </mat-datepicker>
</mat-form-field>