import { Component, OnInit, Input } from '@angular/core';
import { StepPrompt} from '../../classes/step-prompt';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-nx',
  templateUrl: './prompt-next.component.html'
})
export class PromptNextComponent  extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPrompt;

  constructor() {   
    super(); 
  }

  ngOnInit(): void {
    this.getGroupOptions(this.stepData);
  }

  process() {
    this.stepData.processNext();
  }

}