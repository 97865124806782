import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './modules/material/material.module';
import { AssistModule } from 'carina-workflow-assist';  
import { AssistHostModule } from './modules/assist-host/assist-host.module'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './shared/components/header/header.component';
import { ControlBarComponent } from './shared/components/control-bar/control-bar.component';

@NgModule({ 
  declarations: [
    AppComponent,  
    HeaderComponent,
    ControlBarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AssistHostModule,
    AssistModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
