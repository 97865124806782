export enum EnumValueType {
  Value_boolean,
  Value_string,
  Value_number,
  Value_object,
  Value_reference,
  Value_unknown,  // used by the compiler
  Value_array,  // used by the compiler
  Value_property,  // used by the compiler
  Value_name,  // used by the compiler
  Value_null  // used by the compiler
}
