import { EnumEventType } from "../enums/enum-event-type";

export class EventData {

  type : EnumEventType;
  data : any;

  constructor (type : EnumEventType, data? : any) {
    this.type = type;
    this.data = data;
  }

}
