import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssistHostComponent } from './modules/assist-host/assist-host.component'

const routes: Routes = [ 
 
  //{
  //   path : ':orgUrl',
  //   children : [
  //     { path: 'signon', component: SignOnComponent},
  //     { path: 'home', component: HomeComponent, canActivate:[AuthGuard]},
  //     { path: 'configure', component: ConfigureComponent, canActivate:[AuthGuard]},
  //     { path: 'assist', component: AssistHostComponent},
  //     //{ path: 'stats', loadChildren: () => import('./modules/stat-entry/stat-entry.module').then(m => m.StatEntryModule), canActivate: [AuthGuard] },
  //     { path: '', redirectTo: '/assist', pathMatch: 'full' },
  //     { path: '**', redirectTo: '/home', pathMatch: 'full' }
  //   ],
  //   canActivate:[OrgGuard],
  //   data : {ngRoutes:["configure"]}
  // },

  //{ path: 'assist', component: AssistHostComponent}, 
  //{ path: '', redirectTo: '/assist', pathMatch: 'full'},
  { path: 'assist', component: AssistHostComponent, data:{name:"KargerCaseReportEthics"}},
  { path: 'kargerx', component: AssistHostComponent, data:{name:"IOP_EthicsGen"}},
  { path: 'mynewroute', component: AssistHostComponent, data:{name:"IOP_EthicsGen"}},
  { path: 'iop', component: AssistHostComponent, data:{name:"IOP_EthicsGen"}},
  { path: 'default', component: AssistHostComponent, data:{name:"default"}},
  { path: '', redirectTo: '/default', pathMatch: 'full'},
  { path: '**', redirectTo: '/default', pathMatch: 'full'}      
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 