import { Component, OnInit, Input} from '@angular/core';
import { AssistValues } from '../../classes/assist-values';
import { StepPrompt } from '../../classes/step-prompt';
import { ValueBase } from '../../classes/value-base';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-dr',
  templateUrl: './prompt-dateRange.component.html'
})
export class PromptDateRangeComponent  extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPrompt;

  fromDate : Date = null;
  toDate : Date = null;

  formatDate : string = 'm/Y';
  formatRange : string = '$1 - $2';

  constructor() {
    super(); 
  }

  ngOnInit(): void {

    this.getGroupOptions(this.stepData);

    var promptConfig = this.stepData.promptConfig;

    if (promptConfig.hasOwnProperty("formatDate") && typeof promptConfig.formatDate == "string") {
      this.formatDate = promptConfig.formatDate;
    }

    if (promptConfig.hasOwnProperty("formatRange") && typeof promptConfig.formatRange == "string") {
      this.formatRange = promptConfig.formatRange;
    }

    let dataValue : ValueBase = this.stepData.dataValue;
  }

  get isValid() : boolean {
    return this.stepData.isValid || this.stepData.isPristine;
  }
 
  setStepValue(value : any) {
    this.stepData.value = value;
    this.stepData.updateValue();
  }

  process(choice: string) {
    this.stepData.processNext();
  }

  change() {
    this.stepData.updateValue();
  }

  onFocus() {
    this.stepData.setHighlights(true);
  }

  onBlur() {
    this.stepData.setStatus();
    this.stepData.setHighlights(false);
  }

  fromDateChange(newDate: Date | null) {

    this.fromDate = newDate;

    this.setStepValue(this.formatDateRange());

  }

  toDateChange(newDate: Date | null) {

    this.toDate = newDate;

    this.setStepValue(this.formatDateRange());

  }

  formatDateRange() : string {
    let strRange = this.formatRange;
    let strFromDate = "";
    let strToDate = "";
    
    let assistValues = AssistValues.getInstance();

    if (this.fromDate != null) {
      strFromDate = assistValues.formatDate(this.fromDate, this.formatDate);
    }

    if (this.toDate != null) {
      strToDate = assistValues.formatDate(this.toDate, this.formatDate);
    }

    if (strRange.indexOf("$1") != -1) {
      strRange = strRange.replace("$1", strFromDate);
    }      

    if (strRange.indexOf("$2") != -1) {
      strRange = strRange.replace("$2", strToDate);
    }      

    return strRange;
  }

}
