export class HeaderButton {
  public id :string;
  public name : string;
  public title : string;
  public disabled : boolean;
  public navigate : string;

  constructor(id : string, name: string, title:string, disabled: boolean, navigate:string) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.disabled = disabled;
    this.navigate = navigate;
  }

}
