<!-- Inputs -->
<ng-content></ng-content>

<!-- Track -->
<div class="mdc-slider__track">
  <div class="mdc-slider__track--inactive"></div>
  <div class="mdc-slider__track--active">
    <div #trackActive class="mdc-slider__track--active_fill"></div>
  </div>
  <div *ngIf="showTickMarks" class="mdc-slider__tick-marks" #tickMarkContainer>
    <ng-container *ngIf="_cachedWidth">
        <div
          *ngFor="let tickMark of _tickMarks; let i = index"
          [class]="tickMark === 0 ? 'mdc-slider__tick-mark--active' : 'mdc-slider__tick-mark--inactive'"
          [style.transform]="_calcTickMarkTransform(i)"></div>
    </ng-container>
  </div>
</div>

<!-- Thumbs -->
<mat-slider-visual-thumb
  *ngIf="_isRange"
  [discrete]="discrete"
  [thumbPosition]="1"
  [valueIndicatorText]="startValueIndicatorText">
</mat-slider-visual-thumb>

<mat-slider-visual-thumb
  [discrete]="discrete"
  [thumbPosition]="2"
  [valueIndicatorText]="endValueIndicatorText">
</mat-slider-visual-thumb>
