
import { EnumEventType } from "../enums/enum-event-type";
import { EventData } from "./event-data";
import { StepActionEntry } from "./step-action-entry";
import { StepActionEntryInc } from "./step-action-entry-inc";
import { StepBase } from "./step-base";
import { IStepActionItem } from "../interfaces/istep-action-item";
import { BranchData } from "./step-branch";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { ActionDataType } from "../types/action-data-type";
import { ValueBase } from "./value-base";
import { EnumValueType } from "../enums/enum-value-type";

export class StepActionItem extends StepBase {

  show: boolean = false;
  name : string;
  title : string;
  buttonText : string;
  complete: boolean = false;
  doneProcess : boolean = false;
  processFailure : boolean = false;
  inProgress: boolean = false;
  stepEntries : StepActionEntry[] = [];
  actionEval: EvalNodeType;
  isPending : boolean = true;
  actionConfig: any = {};
  valueName : string = "";
  dataValue : ValueBase = null;
  actionConfigEval: EvalNodeType = null;

  private _config : IStepActionItem;

  constructor(index: number, config: IStepActionItem) {
    super(index, config);
    this.name = config.name;
    this.title = config.title;
    this.buttonText = config.buttonText;

    this._config = config;
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.actions !== undefined) {
      evalNodes.push(this._config.actions);
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      evalNodes.push(this._config.actionConfig); 
    } 

    if (this._config.hasOwnProperty("valueName")) {
      this.valueName = this._config.valueName;
    }     
    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

    if (this._config.actions !== undefined) {
      this.actionEval = evalNodes.splice(0,1)[0];
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      this.actionConfigEval = evalNodes.splice(0,1)[0];
    } 

    this._config = null;

  }

  initialise (topBranch : BranchData) {
    super.initialise(topBranch);

    this._subscriptions.push (
      topBranch.branchEvent.subscribe(event => {
        this.handleBranchEvent(event);
      })
    ); 
  }

  destroy () {
    this.stepEntries.forEach(action => action.destroy());
  }

  addEntry (stepActionEntry: StepActionEntry) {
    this.stepEntries.push(stepActionEntry);

    this.entrySubscribe(stepActionEntry);

  }

  sortEntries() {

    this.stepEntries.sort((e1 : StepActionEntry, e2: StepActionEntry) =>{
      
      if (e1.sortIndex < e2.sortIndex) {
        return -1;
      } 
      return 1;
    });
  }

  addEntryInc (stepActionEntryInc: StepActionEntryInc) {
    this.entrySubscribe(stepActionEntryInc);
  }

  entrySubscribe (stepData : StepBase) {
    this._subscriptions.push (
      stepData.stepEvent.subscribe((event) => {
        this.handleActionEvent(event);
      })
    );
  }

  private async setActionConfig (evalNode : IEvalNodeType) {

    this.actionConfig = {};

    try {
      let configDataValue = await this.assistEval.evaluate(evalNode);
      if (configDataValue.isValue && configDataValue.isObject) {
        this.actionConfig = configDataValue.getValue();
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  deactivate(): boolean {
    let result : boolean;

    result = super.deactivate();

    return result;
    
  }


  async activate(): Promise<boolean> {
    let result: boolean;

    if (this.actionConfigEval != null) {
      await this.setActionConfig(this.actionConfigEval);
    }
    
    result = await super.activate();
    
    if (this.valueName !== "" && this.dataValue === null) {
      this.dataValue =  this.scope.findDeclareValue({type:EnumValueType.Value_object, name: this.valueName});
    }    

    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }  

  handleActionEvent(event: EventData) {

    if (event.type == EnumEventType.actionEntrySort) {
      this.sortEntries();
    } else {
      
      let stepData : StepActionEntryInc = event.data.stepData;
      
      if (stepData) {

        let stepActionEntry : StepActionEntry = null;       

        if (this.stepEntries.some(actioEntry => 
          {
            if (actioEntry.entryName == stepData.entryName) {
              stepActionEntry = actioEntry;
              return true;
            } 
            return false;
          })
        ) {
          stepActionEntry.updateEntryCount(event.type == EnumEventType.stepComplete, stepData.valueNames); 
        }
      }

      this.show = this.stepEntries.some(stepAction => stepAction.active && stepAction.entryCount > 0);
    }

    if (this.dataValue !== null) {
      this.dataValue.setValue(this.getActionData());
    }
  }

  handleBranchEvent(event: EventData) {
    let complete = this.complete;
    if (event.type == EnumEventType.branchComplete) {
      complete = true;

    } else if (event.type == EnumEventType.branchIncomplete) {
      complete = false;
    }

    if (complete !== this.complete) {
      this.complete = complete;
    }
  }

  getActionData() : any {
    
    let actionData : ActionDataType = {text : [], content: [], data : []};
 
    this.stepEntries.forEach(stepAction => {
      if (stepAction.active && stepAction.entryCount> 0 && stepAction.canProcess) {

        stepAction.getActionData(actionData);

      }
    });
    
    return actionData;

  }

  async processAction(): Promise<void> {
 
    if (await this.assistStateService.checkSession()) {

      let actionData = this.getActionData();
      //this.messagePoster.postMessage('fromAppRunMacro', actionInfo);
  
      this.isPending = true;
      this.processFailure = false;
  
      let dataValue = await this.assistEval.evaluate(this.actionEval, [actionData]);
  
      if (dataValue.isValue) {
        this.doneProcess = true;
        this.processFailure = !(dataValue.getValue() as boolean);
      } else {
        this.processFailure = dataValue.isError;
      }
      
      this.isPending = false;
  
      this.sendEvent(EnumEventType.actionCompleted, this);
    }
  }   

  get canProcess() : boolean {
    return this.stepEntries.some(stepAction => stepAction.canProcess);
  }

  get isOutstanding() : boolean {
    return this.show && (this.processFailure || !this.doneProcess);
  }

}

