import { Component, OnInit} from '@angular/core';
import { IStepComponent } from '../../interfaces/istep-component';
import { PromptMultiComponent } from './prompt-multi.component'

@Component({
  selector: 'carina-step-bt',
  templateUrl: './prompt-button.component.html'
})
export class PromptButtonComponent extends PromptMultiComponent implements OnInit, IStepComponent {

  constructor( ) {
    super();
  } 


}
