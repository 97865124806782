
import { EnumEventType } from "../enums/enum-event-type";
import { StepBase } from "./step-base";
import { ValueBase } from "./value-base";
import { BranchData } from "./step-branch";

import { IStepActionEntryInc } from "../interfaces/istep-action-entry-inc";

export class StepActionEntryInc extends StepBase {

  actionName : string;
  entryName : string;
  incValue : number = 1;
  valueNames : string[] = [];
  dataValues : ValueBase[] = [];

  constructor(
    index: number,
    config: IStepActionEntryInc
  ) {
    super(index, config);

    this.actionName = config.actionName;
    this.entryName = config.entryName;
  
    if (config.hasOwnProperty("valueNames")) {
      this.valueNames = config.valueNames;
    }    

  }

  initialise (topBranch : BranchData) {
    super.initialise(topBranch);
  }  

  destroy () {
    super.destroy();
  }  

  async activate(): Promise<boolean> {
    let result: boolean;

    if (await super.activate()) {
      this.sendEvent(EnumEventType.stepComplete, { index: this.index, stepData : this});
    };

    return result;
  }

  deactivate(): boolean {
    let result: boolean;

    if (super.deactivate()) {
      this.sendEvent(EnumEventType.stepIncomplete, { index: this.index, stepData : this});
    };


    return result;
  }

}