import { ValueBase } from "./value-base";
import { StepValueItem } from "./step-value-item";
import { ValueFactory } from "./value-factory";
import { MessagePoster } from "./message-poster";
import { EnumValueType } from "../enums/enum-value-type";
import { ValueObject } from "./value-object";
import { Observable, Subject } from "rxjs";
import { StepBase } from "./step-base";
import { AssistInjector } from '../services/assist-injector';
import { AssistStateService } from "../services/assist-state.service";
import { catchError, map } from "rxjs/operators";
import { Scope } from "./scope";

export class AssistValues {

  private static instance : AssistValues;

  protected _declared: Subject<string> = new Subject();
  public readonly onValueDeclared: Observable<string> = this._declared.asObservable();

  protected _stepCreated: Subject<StepBase> = new Subject();
  public readonly onStepCreated: Observable<StepBase> = this._stepCreated.asObservable();

  protected _fetchedValue: Subject<ValueBase> = new Subject();
  public readonly onValueFetched: Observable<ValueBase> = this._fetchedValue.asObservable();

  protected _completedMacro: Subject<ValueBase> = new Subject();
  public readonly onMacroCompleted: Observable<ValueBase> = this._completedMacro.asObservable();

  valueItems: StepValueItem[];

  private messagePoster = new MessagePoster();

  public stepCount : number = 0;
  public scriptParams : any = {};

  private pendingValueName : string = null;
  private pendingMacroName : string = null;
  private assistStateService : AssistStateService;

  private constructor() {

    this.assistStateService = AssistInjector.get(AssistStateService);
  };


  public static getInstance(): AssistValues {
    if (!AssistValues.instance) {
      AssistValues.instance = new AssistValues();
    }  

    return AssistValues.instance;
  }  

  initialise(params: any) : boolean{

    let result = true;
    
    if (params !== undefined && params !== null) {
      this.scriptParams = params;
    }

    return result;
  }

  reset () {
    this.stepCount = 0;
  }

  destroy () {
    if (this.pendingValueName) {
      this._fetchedValue.next(ValueFactory.createErrorValue(EnumValueType.Value_string, "Script cancelled fetching: '" + this.pendingValueName + "'", null));
      this.pendingValueName = null;
    }
    if (this.pendingMacroName) {
      this._completedMacro.next(ValueFactory.createErrorValue(EnumValueType.Value_string, "Script cancelled running: '" + this.pendingMacroName + "'", null));
      this.pendingMacroName = null;
    }
  }

  fetchValue(valueName: string, params: any) : Promise<ValueBase> {

    return new Promise<ValueBase>(async (resolve, reject) => {

      this.assistStateService.setSystemInProgress(true);
  
      let retry : boolean = false;
      let dataValue : ValueBase;

      do {

        this.pendingValueName = valueName;
        this.messagePoster.postMessage('fromAppGetValue', { name: valueName, params: params });

        dataValue = await this.waitForFetchValue();

        if (dataValue.isError && dataValue.canRetry) {

          retry = await this.assistStateService.canRetryError(dataValue);
          
          if (!retry) {
            this.assistStateService.setUserCancel(true);
          }          

        } else {
          retry = false;
        }

      } while (retry);

      resolve(dataValue);
      this.assistStateService.setSystemInProgress(false);      

    });

  }

  waitForFetchValue () : Promise<ValueBase> {

    return new Promise<ValueBase>(async (resolve, reject) => {

      let subscription = this.onValueFetched.subscribe(dataValue => {

        subscription.unsubscribe();

        resolve(dataValue);

      })

    })
  }

  setPendingValue (valueName: string, data: any) {

    let dataValueName : string = this.pendingValueName;
    this.pendingValueName = null;
    let type : EnumValueType = EnumValueType.Value_boolean;
    let dataValue : ValueBase;
    
    if (data.hasOwnProperty("value") && data.hasOwnProperty("type")) {
      
      let value = data.value;
      let valueType = data.type; 

      switch (valueType) {
        case "object":
        case "array":
          type = EnumValueType.Value_object;
        break;  
        case "boolean":
          type = EnumValueType.Value_boolean;
        break;  
        case "number":
          type = EnumValueType.Value_number;
        break;  
        case "string":
          type = EnumValueType.Value_string;
        break;  
      }

      if (dataValueName !== null && dataValueName == valueName) {
        dataValue = ValueFactory.create({name: valueName, type:type, value: value});
      } else {
        dataValue = ValueFactory.createErrorValue(type, "pending value name mismatch. Trying to set: '" + dataValueName + "'with '" + valueName + "'", null);
      }
    } else {
      if (data.hasOwnProperty("error")) {
        dataValue = ValueFactory.createErrorValue(type, data.error + " fetching: '" + dataValueName + "'", null);
      } else if (data.hasOwnProperty("errNum")) {
        dataValue = ValueFactory.createErrorData(type, data, null);
      } else {
        dataValue = ValueFactory.createErrorValue(type, "Unexpected error fetching: '" + dataValueName + "'", null);
      }
    }

    this._fetchedValue.next(dataValue);

  }

  runMacro(macroName: string, values: any) : Promise<ValueBase>{

    return new Promise<ValueBase>(async(resolve, reject) => {

      this.assistStateService.setSystemInProgress(true);
  
      let retry : boolean = false;
      let dataValue : ValueBase;

      do {
  
        this.pendingMacroName = macroName;
        this.messagePoster.postMessage('fromAppRunMacro', { macroName: macroName, values: values });

        dataValue = await this.waitForMacroComplete();

        if (dataValue.isError && dataValue.canRetry) {

          retry = await this.assistStateService.canRetryError(dataValue);

          if (!retry) {
            dataValue = ValueFactory.createFromLiteral(false);

            this.assistStateService.setUserCancel(true);
          }

        } else {
          retry = false;
        }

      } while (retry);

      resolve(dataValue);
      this.assistStateService.setSystemInProgress(false);

    });

  }

  waitForMacroComplete () : Promise<ValueBase> {

    return new Promise<ValueBase>(async (resolve, reject) => {

      let subscription = this.onMacroCompleted.subscribe(dataValue => {

        subscription.unsubscribe();

        resolve(dataValue);

      })

    })
  }


  completeMacro(macroName :string, data: any) {

    
    let pendingMacroName : string = this.pendingMacroName;
    this.pendingMacroName = null;
    let dataValue : ValueBase;

    if (data.hasOwnProperty("result")) {
      
      if (pendingMacroName && pendingMacroName == macroName) {
        if (data.result) {
          dataValue = ValueFactory.createFromLiteral(data.result);
        } else {
          if (data.hasOwnProperty("message")) {
            dataValue = ValueFactory.createErrorValue(EnumValueType.Value_boolean, "Error: " + data.message + " running macro '" + macroName + "'", null);
          } else if (data.hasOwnProperty("errNum")) {
            dataValue = ValueFactory.createErrorData(EnumValueType.Value_boolean, data, null);
          } else {
            dataValue = ValueFactory.createErrorValue(EnumValueType.Value_boolean, "Unexpected message running macro '" + macroName + "'", null);
          }
        }
      } else {
        dataValue = ValueFactory.createErrorValue(EnumValueType.Value_boolean, "pending macro name mismatch. Trying to set: '" + pendingMacroName + "'with '" + macroName + "'", null);
      }
    } else {
      dataValue = ValueFactory.createErrorValue(EnumValueType.Value_boolean, "Unexpected error running macro '" + macroName + "'", null);
    }

    this._completedMacro.next(dataValue);

  }

  metaDataCheck(metaDataName: string, params: any, metadata: any) : Promise<ValueBase>{

    return new Promise<ValueBase>((resolve, reject) => {

      this.assistStateService.setSystemInProgress(true);

      let request$ = this.assistStateService.metaDataCheck(metaDataName, params, metadata ).pipe (
        map(response => {
          this.assistStateService.setSystemInProgress(false);
          if (response && response.hasOwnProperty("result"))  {         
            if (response.result == "OK" && response.hasOwnProperty("response")) {
              let metadata = response.response;

              resolve(ValueFactory.createFromLiteral(metadata));
            } else if (response.hasOwnProperty("errors")) {
              let strError = ((response as any).errors as string[]).join(',');
              resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, strError, null));
            }
          } else {
            resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, "unexpected error in metaDataCheck: " + metaDataName, null));
          }
        }),
        catchError (err => {
          this.assistStateService.setSystemInProgress(false);
          resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, err.message, null));
          return null;
        })
      ).subscribe();

    });

  }

  writeLog(logTypeCode: string, params: any) : Promise<ValueBase>{

    return new Promise<ValueBase>((resolve, reject) => {

      this.assistStateService.setSystemInProgress(true);

      let request$ = this.assistStateService.writeLog(logTypeCode, params).pipe (
        map(response => {
          this.assistStateService.setSystemInProgress(false);
          if (response && response.hasOwnProperty("result"))  {         
            if (response.result == "OK" && response.hasOwnProperty("response")) {
              let metadata = response.response;

              resolve(ValueFactory.createFromLiteral(metadata));
            } else if (response.hasOwnProperty("errors")) {
              let strError = ((response as any).errors as string[]).join(',');
              resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, strError, null));
            }
          } else {
            resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, "unexpected error in metaDataCheck: " + logTypeCode, null));
          }
        }),
        catchError (err => {
          this.assistStateService.setSystemInProgress(false);
          resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, err.message, null));
          return null;
        })
      ).subscribe();

    });

  }

  createDocument(libName: string, sourceName: string, targetName: string, docData: any) : Promise<ValueBase>{

    return new Promise<ValueBase>((resolve, reject) => {

      this.assistStateService.setSystemInProgress(true);

      let request$ = this.assistStateService.createDocument(libName, sourceName, targetName, docData).pipe (
        map(response => {
          this.assistStateService.setSystemInProgress(false);
          if (response && response.hasOwnProperty("result"))  {         
            if (response.result == "OK" && response.hasOwnProperty("response")) {
              let result = false;
              let retObj = response.response;

              if (retObj.result == "OK" && retObj.requestId !== "") {

                let strUrl = this.assistStateService.getDownloadUrl(retObj.requestId);

                let win = window.open(strUrl, "taAssistDownload");

                result = true;
              }

              resolve(ValueFactory.createFromLiteral(result));
            } else if (response.hasOwnProperty("errors")) {
              let strError = ((response as any).errors as string[]).join(',');
              resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, strError, null));
            }
          } else {
            resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, "unexpected error in createDocuments: " + sourceName, null));
          }
        }),
        catchError (err => {
          this.assistStateService.setSystemInProgress(false);
          resolve(ValueFactory.createErrorValue(EnumValueType.Value_object, err.message, null));
          return null;
        })
      ).subscribe();

    });

  }

  activateTab() : void {
    this.assistStateService.activateTab();
  }

  unescapeQuote (sourceString : string) : string {

    const regex = /\\"/g;

    return sourceString.replace(regex, '"');
  }

  formatText(scope : Scope, text : string, valueNames : string[] = null) : string {

    let result : string;

    let getNames : boolean = valueNames != null && Array.isArray(valueNames);
    let offset = 0;
    let matches = null;
    let dataValue : ValueBase = null;
    result = "";
    text = this.unescapeQuote(text);

    let fieldRegex = /\{\s*([a-zA-Z_\d\.]*)\s*(\|\s*([a-zA-Z_\d"\.,:\s\\\-\(\)]*))?\}/;
    let operandRegex = /[a-zA-Z0-9\s,;:-_\\\.\-\(\)']+/;

    do {
      let str = text.substring(offset);

      matches = str.match(fieldRegex); 

      if (matches) {
        let valueName = matches[1];

        let path = valueName.split(".");

        if (path.length == 1) {
          dataValue = scope.findValue(valueName);
        } else {
          
          valueName = path.shift();
          dataValue = scope.findValue(valueName);

          dataValue = ValueFactory.createValueReference(dataValue, path);
        }

        if (getNames && !valueNames.includes(valueName)) {
          valueNames.push(valueName);
        } 

        result += str.substring(0, matches.index);

        offset += matches.index + matches[0].length;

        if (dataValue) {
          if (matches.length >= 3 &&  matches[3] !== undefined) {

            let formatting :string [] = matches[3].split(':');

            if (formatting != null) {
            
              formatting.forEach((operand, index) => {
                let entry = operand.trim().match(operandRegex);
                if (entry != null) {
                formatting[index] = operand.trim().match(operandRegex)[0];
                } else {
                  formatting[index] = "";
                }
              });  

              switch (formatting[0]) {

                case 'arrayConcat':
                  let sep = ', ';
                  let lastSep = ', ';

                  if (formatting.length > 1) {
                    sep = formatting[1];
                  }  
                  if (formatting.length > 2) {
                    lastSep = formatting[2];
                  } else {
                    lastSep = sep;
                  }

                  dataValue = this.arrayConcat(dataValue, sep, lastSep);              

                break;
                case 'plural':

                  formatting.shift();
                  dataValue = this.plural(dataValue, formatting);

                break;
                case 'bulletList':

                  formatting.shift();
                  dataValue = this.bulletList(dataValue, formatting);

                break;
                case 'numberedList':

                  formatting.shift();
                  dataValue = this.numberedList(dataValue, formatting);

                break;
              }
            }
          }

          if (dataValue.isValue) {
            result += dataValue.cast(EnumValueType.Value_string) as string;
          } else {
            result += dataValue.error;
          }
        } else {
          result += "undefined";
        }

      } else {

        result += str.substring(0);
      }
    } while (matches != null && offset < text.length);
  

    return result;

  }

  arrayConcat (dataValue : ValueBase,  separator : string, lastSeparator : string) : ValueBase {

    let result : ValueBase;
    let concatText : string = "";

    if (dataValue.isArray) {
      concatText = (dataValue as ValueObject).arrayConcat(separator, lastSeparator);

      result = ValueFactory.createFromLiteral(concatText);
    } else {
      result = ValueFactory.createErrorValue(EnumValueType.Value_string, "Cannot concat non-array value", null);
    }

    return result;
  }

  plural (dataValue : ValueBase,  altText : string[]) : ValueBase {

    let pluralText : string  = "";
    let isPlural : boolean = dataValue.isValue && dataValue.isPlural; 

    if (isPlural && altText.length > 1) {
      pluralText = altText[1];
    } else if (altText.length > 0) {
      pluralText = altText[0];
    } else {
      pluralText = "Invalid plural specified";
    }

    return ValueFactory.createFromLiteral(pluralText);
  }

  bulletList (dataValue : ValueBase,  formatting : string[]) : ValueBase {

    let result : ValueBase;
    let hasError : boolean = false;
    let entries : string[];
    let listText : string  = "";
    let strBullet : string = '- ';
    let strEnd : string = '.';
    let strPrefix : string = strBullet;

    if (formatting.length > 0) {
      strBullet = formatting[0];
    }  
    if (formatting.length > 1) {
      strEnd = formatting[1];
    } 

    strPrefix = strBullet;

    if (dataValue.isValue) {
      if (dataValue.isArray) {

        entries = dataValue.getValue() as string[];

        entries.forEach(entry => {

          if (typeof entry === "object") {            
            entry = '[object]';
          }
          
          listText += strPrefix + entry;

          strPrefix = '\n' + strBullet;

        });

        listText = listText + strEnd;
      } else {
        hasError = true;
        result = ValueFactory.createErrorValue(EnumValueType.Value_string, "Cannot create bullet list from non-array value", null);
      }
    } else {
      hasError = true;
      result = dataValue;
    }

    if (!hasError) {      
      result = ValueFactory.createFromLiteral(listText);
    }

    return result;
  }

  numberedList (dataValue : ValueBase,  formatting : string[]) : ValueBase {

    let result : ValueBase;
    let hasError : boolean = false;
    let entries : string[];
    let listText : string  = "";
    let strNumber : string = 'index. ';
    let isNumeral : boolean = false;
    let strEnd : string = '.';
    let strPrefix : string = "";

    if (formatting.length > 0) {
      strNumber = formatting[0];
      isNumeral = strNumber.match(new RegExp(/numeral/)) !== null;
    }  
    if (formatting.length > 1) {
      strEnd = formatting[1];
    } 

    if (dataValue.isValue) {
      if (dataValue.isArray) {

        entries = dataValue.getValue() as string[];

        let strStart : string = "";
        entries.forEach((entry, index) => {

          let numberIndex = index + 1;
          if  (isNumeral) {
            strStart = strNumber.replace('numeral', ""+this.numeral(numberIndex));
          } else {
            strStart = strNumber.replace('index', ""+numberIndex);
          }
          
          listText += strPrefix + strStart + entry;

          strPrefix = '\n';

        });

        listText = listText + strEnd;
      } else {
        hasError = true;
        result = ValueFactory.createErrorValue(EnumValueType.Value_string, "Cannot create numbered list from non-array value", null);
      }
    } else {
      hasError = true;
      result = dataValue;
    }

    if (!hasError) {      
      result = ValueFactory.createFromLiteral(listText);
    }

    return result;
  }

  numeral (num : number) : string{
    if (!+num)
      return "";
    let	digits = String(+num).split(""),
    key = ["","c","cc","ccc","cd","d","dc","dcc","dccc","cm",
           "","x","xx","xxx","xl","l","lx","lxx","lxxx","xc",
           "","i","ii","iii","iv","v","vi","vii","viii","ix"],
      roman = "",
      i = 3;
    while (i--)
      roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }  

  stepCreated(stepData : StepBase) {

    this._stepCreated.next(stepData);

  }

  stepAdded() {
    this.stepCount++;
  }

  getAddIndex() : number {
    return this.stepCount;
  }

  formatDate(dt : Date, format : string) : string {

    let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let dateString : string = format;

    if (dateString.indexOf("d") != -1) {
      let day = "0"+dt.getDate();
      dateString = dateString.replace("d", day.substring(day.length-2));
    } 
    
    if (dateString.indexOf("j") != -1) {
      dateString = dateString.replace("j", ""+dt.getDate());
    } 

    if (dateString.indexOf("y") != -1) {
      let year = ""+dt.getFullYear();
      dateString = dateString.replace("y", year.substring(year.length-2));
    }    

    if (dateString.indexOf("Y") != -1) {
      dateString = dateString.replace("Y", ""+dt.getFullYear());
    }    
    
    if (dateString.indexOf("m") != -1) {
      let month = "0"+(dt.getMonth()+1);
      dateString = dateString.replace("m", month.substring(month.length-2));
    }    

    if (dateString.indexOf("n") != -1) {
      dateString = dateString.replace("n", ""+(dt.getMonth()+1));
    }    

    if (dateString.indexOf("M") != -1) {
      dateString = dateString.replace("M", months[dt.getMonth()]);
    } 

    return dateString;
  }
}
