import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderButton } from '../../shared/classes/header-button';
import { Subscription } from 'rxjs';
import { AssistContextService } from 'src/app/shared/services/assist.context.service';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'carina-assist-host',
  templateUrl: './assist-host.component.html',
  styleUrls: ['./assist-host.component.scss']
})
export class AssistHostComponent implements OnInit, AfterViewInit, OnDestroy {  
  
  private _subscriptions: Subscription[] = [];
  private routeData: object = {name:"defautl"};


  constructor(
    private route: ActivatedRoute,
    //private authService: AuthService,
    private appService: AppService,
    public assistContextService: AssistContextService) 
  { }

  ngOnInit() {
    //this.assistContextService.setPageLayout("wide");      
    this.assistContextService.setPageTitle("EthicsGen");
    this.assistContextService.setContentLayout("Row");
    this.initPage();

  }

  ngAfterViewInit(): void { 
    this.assistContextService.setAction("create");
    this.assistContextService.setAction("open");
    this._subscriptions.push(
      this.route.data.subscribe(data => {
        this.routeData = data;
        this.assistContextService.setAction("load", this.routeData);
      })
    );    
  }

  ngOnDestroy() : void {

    this.assistContextService.setAction("close");

    this._subscriptions.forEach(subscription => subscription.unsubscribe());
   // this.appService.setPageLayout("default");
  }

  initPage(): void {

    let buttons: HeaderButton[] = [];

    buttons.push(new HeaderButton("hdrbtnReset", "reset", "Reset Workflow Assist", false, ""));
    
    //buttons.push(new HeaderButton("hdrbtnHome", "home", "Home", false, "/home")); 

    this.appService.setHeaderButtons(buttons);
     
    this._subscriptions.push (
     this.appService.onHeaderButtonsPress.subscribe(button => {
       this.handleHeaderButton(button);
     })
    );

    this.assistContextService.setAction("open");
  }

  handleHeaderButton(button : HeaderButton) {

    if (button.name == "reset") {
      this.assistContextService.setAction("reset");
    }

  }


}
