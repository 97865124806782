import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(value: any, txtTrue: any, txtFalse : any): any {
    let strTrue  = typeof txtTrue === "string" ? txtTrue : "Yes";
    let strFalse = typeof txtFalse === "string" ? txtFalse : "No";
    
    return value ? strTrue : strFalse;
  }

}
