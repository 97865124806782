import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nbspOnEmpty'
})
export class NbspOnEmptyPipe implements PipeTransform {

  text :string 

  transform(value: string): string {

    if (typeof value === "string" && value.length == 0) {
      return '\u2007';
    }   
    
    return value;
  } 

}
