import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepEvalBranch } from '../../classes/step-eval-branch';

import { StepEvalSet } from '../../classes/step-eval-set';
import { StepEvalDeclare } from '../../classes/step-eval-declare';
import { EnumDebugLevel } from '../../enums/enum-debug-level';
import { IStepComponent } from '../../interfaces/istep-component';
import { StepBaseComponent } from './step-base.component';
import { AssistStateService } from '../../services/assist-state.service';

@Component({
  selector: 'carina-step-eval-set',
  templateUrl: './step-eval.component.html'
})
export class StepEvalComponent extends StepBaseComponent implements IStepComponent {
  
  @Input() stepData: StepEvalSet | StepEvalBranch | StepEvalDeclare;
  public debugLevel : EnumDebugLevel;

  constructor(private assistStateService: AssistStateService) { 
    super(); 
    this._subscriptions.push (
      this.assistStateService.debugLevel.subscribe((level) => { 
        this.debugLevel = level;
      }) 
    );
  }  

  get show () : boolean {
    return this.stepData.active && (
      (this.stepData.processText !== "") ||
      (this.stepData.dataValue !== null && this.stepData.dataValue.isError) ||
      (this.debugLevel == EnumDebugLevel.debug_all) ||
      (this.debugLevel == EnumDebugLevel.debug_info && this.stepData.dataValue !== null && this.stepData.dataValue.isPending)
    );
  }  

  get showSpinner () : boolean {
    return (this.stepData.processText !== "" && !(this.stepData.dataValue !== null && this.stepData.dataValue.isError));
  }

}
 