import { Component, OnInit, Input } from '@angular/core';
import { ValueBaseComponent } from './value-base.component';

export interface IChipEntry {
  name : string;
  state : string;
  isSet : boolean;
}

@Component({
  selector: 'carina-value-chip',
  templateUrl: './value-chip.component.html',
  styles: [
  ]
})
export class ValueChipComponent extends ValueBaseComponent implements OnInit {

  public setValue: string = "";
  public entries : IChipEntry [];

  constructor() { 
    super();
  }

  ngOnInit(): void {

    super.ngOnInit();

    if (this.stepData.viewConfig.hasOwnProperty("setValue")) {
      this.setValue = this.stepData.viewConfig.setValue;
    }

  }

  findDeclaredValue() {

    let result = super.findDeclaredValue();

    if (result && this.dataValue) {

      result = true;

      this._subscriptions.push (
        this.dataValue.onChangeValue.subscribe(value => {
          this.handleValue();
        })
      );
    }
    
    return result;
  }

  handleValue () {

    let isArray : boolean;
    let strValue : string = "";

    this.entries = [];

    if (this.dataValue.isValue) {
      if (this.dataValue.isArray) {
        let arrValue : any[] = this.dataValue.getValue() as unknown as any[];

        arrValue.forEach(element => {

          if (element.hasOwnProperty("name")) {

            let entry : IChipEntry = {
              name: element.name, 
              state: "",
              isSet: false};

            if (element.hasOwnProperty("state")) {
              entry.state = element.state;
              entry.isSet = entry.state == this.setValue;
            }  

            this.entries.push(entry);

          }    
        })
      } else {
        strValue = this.dataValue.getValue() as string;

        let entry : IChipEntry = {
          name: strValue, 
          state: "",
          isSet:false
        };
          
        this.entries.push(entry);        
      }
    }    
  }
}
