import { Component, OnInit, Input, AfterViewChecked} from '@angular/core';
import { StepPromptMulti } from '../../classes/step-prompt-multi';
import { ValueObject } from '../../classes/value-object';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/istep-component';
import { PromptMultiComponent } from './prompt-multi.component'

@Component({
  selector: 'carina-step-bg',
  templateUrl: './prompt-buttonGroup.component.html'
})
export class PromptButtonGroupComponent extends PromptMultiComponent implements OnInit, IStepComponent {

  constructor( ) {
    super();
  }


}
