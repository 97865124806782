import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { EnumValueType } from "../enums/enum-value-type";
import { EnumValueState } from "../enums/enum-value-state";
import { IDataValue } from "../interfaces/idata-value";
import { IErrorData } from "../interfaces/ierror-data";
import { EnumCarinaErrorType } from '../enums/enum-carina-error-type';

export class ValueBase {

  type : EnumValueType;
  state : EnumValueState;
  isLiteral : boolean = false;
  isAsync : boolean = false;
  name : string;
  error : string;
  errData : IErrorData = null;

  private highlight : boolean = false;

  protected _value: Subject<boolean | number | string | object> = new Subject();
  public readonly onChangeValue: Observable<boolean | number | string | object> = this._value.asObservable();

  protected _state: Subject<EnumValueState> = new Subject();
  public readonly onChangeState: Observable<EnumValueState> = this._state.asObservable();
  
  protected _highlight: Subject<boolean> = new Subject();
  public readonly onChangeHighlight: Observable<boolean> = this._highlight.asObservable();

  constructor(config : IDataValue) {
    this.state = EnumValueState.FieldState_Undefined;
    this.type = config.type;
    this.name = config.name;
    this.error = "";
    if (config.hasOwnProperty("literal")) {
      this.isLiteral = config.literal;
    }
  }

  get isUndefined() : boolean {
    return this.state === EnumValueState.FieldState_Undefined;
  }
  get isPending() : boolean {
    return this.state === EnumValueState.FieldState_Pending;
  }
  get isValue() : boolean {
    return this.state === EnumValueState.FieldState_Value;
  }
  get isError() : boolean {
    return this.state === EnumValueState.FieldState_Error;
  }

  get isBoolean() : boolean {
    return this.type === EnumValueType.Value_boolean;
  }
  get isNumber() : boolean {
    return this.type === EnumValueType.Value_number;
  }
  get isString() : boolean {
    return this.type === EnumValueType.Value_string;
  }
  get isObject() : boolean {
    return this.type === EnumValueType.Value_object;
  }
  get isArray() : boolean {
    return false;
  }
  get isReference() : boolean {
    return false;
  }
  get isObjectArray() : boolean {
    return false ;
  }
  get isPlural() : boolean {
    return false;
  }
  get isHighlighted() : boolean {
    return this.highlight;
  }

  get canRetry() : boolean {
    return this.isError && this.errData !== null && this.errData.errType == EnumCarinaErrorType.errorRetry;
  }
 

  isType(checkType : EnumValueType) : boolean {
    return this.type === checkType;
  }

  reset() {
    this.state = EnumValueState.FieldState_Undefined;
  }

  destroy() {
  }

  canCast(newType : EnumValueType) : boolean {
    return (this.type !== EnumValueType.Value_object) && (newType !== EnumValueType.Value_object);
  }

  cast(newType : EnumValueType) : boolean | string | number {
    return null;
  }

  getValue(): boolean | number | string | object {
    return null;
  }

  setValue(value: boolean | number | string | object) : void {
    this.error = "";
    this.setState(EnumValueState.FieldState_Value);
    //console.log("Set value of " + this.name + " to " + (typeof value == "object"? JSON.stringify(value): value));
  }

  setState(state : EnumValueState) {
    this.state = state;
    this._state.next(this.state);
  }

  setError(errorText : string, setState: boolean = true) {
    this.error = errorText;
    if (setState) {
      this.setState(EnumValueState.FieldState_Error);
    }
  }

  setErrorData(errData : IErrorData) {
    this.errData = errData;
  }

  setHighlight(highlight : boolean) {
    this.highlight = highlight;
    this._highlight.next(this.highlight);
  }

  assignValue(newValue : ValueBase) {
    this.error = newValue.error;
    this.setState(newValue.state);
  }

  arrayCount() : number {
    return 0;
  }

  arrayLength() : number {
    return 0;
  }

  setAsync (async: boolean) {
    this.isAsync = async;
  }

  getPathText() : string{
    return "";
  }
}
