<mat-expansion-panel *ngIf="show" [hideToggle]="contentText.length==0" [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title *ngIf="stepData.label !==''">
      <span  class="valueLabel" [class]="strStyle">{{stepData.label}}:</span>
    </mat-panel-title>
    <mat-panel-description>
      <span *ngIf="isValue" class="valueText">{{valueText}}</span>
      <span *ngIf="isPending"><img src="/assets/images/CarinaInprogress_16x16.gif"></span>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <span *ngIf="isValue" class="valueContent">{{contentText}}</span>
</mat-expansion-panel>