import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { StepActionEntry } from '../../classes/step-action-entry';
import { IActionComponent } from '../../interfaces/iaction-component';

@Component({
  selector: 'carina-action-entry',
  templateUrl: './action-entry.component.html'
})
export class ActionEntryComponent implements OnInit, OnDestroy {

  @Input() stepAction: StepActionEntry;
  @Input() allowCancel: boolean;
  @Input() actionType: string;
  private _highlight : boolean;

  protected _subscriptions: Subscription[] = [];

  checkColour : ThemePalette = 'primary';

  constructor() { }

  ngOnInit(): void {

    if (this.stepAction !== null) {
      this._subscriptions.push(this.stepAction.onChangeHighlight.subscribe(highlight =>{
        this._highlight = highlight;
      }))
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get isHighlight() : boolean {
    return this._highlight;
  }
}