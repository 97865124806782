<ng-container *ngIf="stepData.active">
  <div [attr.carina-indent]="indent">
    <div class="assistStep">
      <div class="stepOffset">
      </div>
      <div #scrollTarget class="stepText stepData">
        <div>
          <p>{{stepData.text[0]}}</p>
          <ng-container *ngIf="stepData.text.length > 1" >
            <ul>
              <li *ngFor="let txt of stepData.text.slice(1)">
                {{txt}}
              </li>
            </ul>   
          </ng-container>
          </div>
        </div>
      <div class="stepButtons">
        <div class="stepButtonEnd">
          <mat-button-toggle-group [(ngModel)]="selected" name="Select" aria-label="Select entry">
            <mat-button-toggle value="yes" class="mat-button" (click)="select()">Select</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div> 
  </div>
</ng-container>