import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AssistConfig } from '../classes/assist-config';
import { Subscription } from 'rxjs';
import { AssistStateService } from '../services/assist-state.service';
import { MessagePoster } from '../classes/message-poster';
import { ViewOptionType } from '../types/view-option-type';
import { IContextService } from '../interfaces/icontext-service'
import { IContextMessage } from '../interfaces/icontext-message'
import { LayoutOptionType } from '../types/layout-option-type';

@Component({
  selector: 'carina-assist',
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.scss']
})
export class AssistComponent implements OnInit {   
  
  @Input() contextService : IContextService;
  @Input() contentLayout : string;

  config: AssistConfig = null;

  public contentClass = "assistContent";

  public stepViewOptions : ViewOptionType = {};
  public dataViewOptions : ViewOptionType = {};
  public actionViewOptions : ViewOptionType = {};

  private _subscriptions: Subscription[] = [];
  private messagePoster = new MessagePoster();

  constructor(
    private assistStateService : AssistStateService
    ) 
  {
    console.log("Constructing AssistComponent");
  }

  ngOnInit(): void {

    this._subscriptions.push (
      this.contextService.onAction.subscribe(message => {
        this.handleAction(message);
      })
    );    
  
    this._subscriptions.push (
      this.assistStateService.onLayout.subscribe(options => { 
        this.setLayoutOptions(options);
      })
    );

    if (this.contentLayout == "row") {
      this.contentClass = "assistContentRow";
    }    

    this.setLayoutOptions(this.contextService.getDefaultLayout());

  }

  setLayoutOptions(options: LayoutOptionType) {

    if (options.hasOwnProperty("stepView")) {
      this.setViewOptions(this.stepViewOptions, options.stepView);
    }
    if (options.hasOwnProperty("dataView")) {
      this.setViewOptions(this.dataViewOptions, options.dataView);
    }
    if (options.hasOwnProperty("actionView")) {
      this.setViewOptions(this.actionViewOptions, options.actionView);
    }

  }

  setViewOptions(options: ViewOptionType, newOptions: ViewOptionType) {

    if (newOptions.hasOwnProperty("enable")) {
      options.enable = newOptions.enable;
    }
    if (newOptions.hasOwnProperty("flex")) {
      options.flex = newOptions.flex;
    }
    if (newOptions.hasOwnProperty("title")) {
      options.title = newOptions.title;
    }

    if (newOptions.hasOwnProperty("layout")) {
      options.layout = newOptions.layout;
    }


  }
  create () {


  }

  initialise () {

    this.assistStateService.setContextService(this.contextService);

    this._subscriptions.push (
      this.assistStateService.workflowConfig.subscribe(config => {
        this.config = config;
      })
    );

    this.assistStateService.setActive(true);

    this.messagePoster.postMessage('fromAppAssistOpen', {});  
  }


  destroy () {

    this.messagePoster.postMessage('fromAppAssistClose', {});  

    this.assistStateService.setActive(false);
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this.assistStateService.assistDeactivate();
    this.assistStateService.assistDeactivate();
    this.contextService.setPageLayout("default");

  }

  handleAction(message : IContextMessage) {

    console.log("AssistComponent: "+message.action);

    if (message.action == "create") {
      this.create();
    }    
    if (message.action == "open") {
      this.initialise();
    }
    if (message.action == "reset") {
      this.assistStateService.reset();
    }
    if (message.action == "close") {
      this.destroy();
    }
    if (message.action == "load") {
      this.assistStateService.loadEthicsGenConfig(message.data.name);
    }

  }

}
