<!--
  Save icons and the pseudo checkbox/radio so that they can be re-used in the template without
  duplication. Also content can only be injected once so we need to extract icons/avatars
  into a template since we use it in multiple places.
-->
<ng-template #icons>
  <ng-content select="[matListItemAvatar],[matListItemIcon]">
  </ng-content>
</ng-template>

<ng-template #checkbox>
  <div class="mdc-checkbox" [class.mdc-checkbox--disabled]="disabled">
    <input type="checkbox" class="mdc-checkbox__native-control"
           [checked]="selected" [disabled]="disabled"/>
    <div class="mdc-checkbox__background">
      <svg class="mdc-checkbox__checkmark"
           viewBox="0 0 24 24"
           aria-hidden="true">
        <path class="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
      </svg>
      <div class="mdc-checkbox__mixedmark"></div>
    </div>
  </div>
</ng-template>

<ng-template #radio>
  <div class="mdc-radio" [class.mdc-radio--disabled]="disabled">
    <input type="radio" class="mdc-radio__native-control"
           [checked]="selected" [disabled]="disabled"/>
    <div class="mdc-radio__background">
      <div class="mdc-radio__outer-circle"></div>
      <div class="mdc-radio__inner-circle"></div>
    </div>
  </div>
</ng-template>

<!-- Container for the checkbox at start. -->
<span class="mdc-list-item__start mat-mdc-list-option-checkbox-before"
      *ngIf="_hasCheckboxAt('before')">
  <ng-template [ngTemplateOutlet]="checkbox"></ng-template>
</span>
<!-- Container for the radio at the start. -->
<span class="mdc-list-item__start mat-mdc-list-option-radio-before"
      *ngIf="_hasRadioAt('before')">
  <ng-template [ngTemplateOutlet]="radio"></ng-template>
</span>
<!-- Conditionally renders icons/avatars before the list item text. -->
<ng-template [ngIf]="_hasIconsOrAvatarsAt('before')">
  <ng-template [ngTemplateOutlet]="icons"></ng-template>
</ng-template>

<!-- Text -->
<span class="mdc-list-item__content">
  <ng-content select="[matListItemTitle]"></ng-content>
  <ng-content select="[matListItemLine]"></ng-content>
  <span #unscopedContent class="mat-mdc-list-item-unscoped-content"
        (cdkObserveContent)="_updateItemLines(true)">
    <ng-content></ng-content>
  </span>
</span>

<!-- Container for the checkbox at the end. -->
<span class="mdc-list-item__end" *ngIf="_hasCheckboxAt('after')">
  <ng-template [ngTemplateOutlet]="checkbox"></ng-template>
</span>
<!-- Container for the radio at the end. -->
<span class="mdc-list-item__end" *ngIf="_hasRadioAt('after')">
  <ng-template [ngTemplateOutlet]="radio"></ng-template>
</span>
<!-- Conditionally renders icons/avatars after the list item text. -->
<ng-template [ngIf]="_hasIconsOrAvatarsAt('after')">
  <ng-template [ngTemplateOutlet]="icons"></ng-template>
</ng-template>

<!-- Divider -->
<ng-content select="mat-divider"></ng-content>

<!--
  Strong focus indicator element. MDC uses the `::before` pseudo element for the default
  focus/hover/selected state, so we need a separate element.
-->
<div class="mat-mdc-focus-indicator"></div>
