import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router'
import { AppService } from '../../services/app.service'
import { Organisation } from '../../classes/organisation'
import { User } from '../../classes/user';
import { HeaderButton } from '../../classes/header-button';


@Component({
  selector: 'carina-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  org : Organisation;
  user : User;
  bDemo : boolean;
  strFullName : string;
  strBannerSrc : string;
  strPageTitle : string;
  arrHeaderButtons: HeaderButton[];
  bInProgress : boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService
  ) {

    this.bDemo = false;

    this.activatedRoute.queryParams.subscribe(params => {
      //let date = params['demo'];
      //console.log(date); // Print the parameter to the console. 
    });

    appService.pageTitle.subscribe(title => {
      this.strPageTitle = title;
    });

    appService.inProgress.subscribe(flag => {
      this.bInProgress = flag;
    });

    appService.headerButtons.subscribe(buttons => {
      this.arrHeaderButtons = buttons;
    });
    
    this.strFullName = "";
    this.strPageTitle = "";
    this.bInProgress = false;
    this.arrHeaderButtons = [];

  }

  ngOnInit(): void {

  }

  hasUser() : boolean { 
    return this.user !== null;
  }

  hasMaint(): boolean {
    return this.user && this.user.hasPermission('MTAL');
  }

  onHeaderButton(index: number) {
    if (index < this.arrHeaderButtons.length) {
      let btn : HeaderButton = this.arrHeaderButtons[index];

      if (btn.navigate) {
        //this.authService.navigate(btn.navigate);
      } else {

        this.appService.headerButtonPress(btn);
      }
    }
  }


}
