
import { EnumEventType } from "../enums/enum-event-type";
import { EventData } from "./event-data";
import { StepActionEntry } from "./step-action-entry";
import { StepActionEntryInc } from "./step-action-entry-inc";
import { StepBase } from "./step-base";
import { BranchData } from "./step-branch";
import { EvalNodeType, IEvalNodeType } from "../types/eval-type";
import { ActionDataType } from "../types/action-data-type";
import { ValueBase } from "./value-base";
import { EnumValueType } from "../enums/enum-value-type";
import { IStepActionButton } from "../interfaces/istep-action-button";

export class StepActionButton extends StepBase {

  show: boolean = false;
  isPending : boolean = false;
  enableOnComplete : boolean = true;
  text : string;
  title : string;
  buttonText : string;
  complete: boolean = false;
  doneProcess : boolean = false;
  processFailure : boolean = false;
  inProgress: boolean = false;
  actionEval: EvalNodeType;
  actionConfig: any = {};
  valueName : string = "";
  dataValue : ValueBase = null;
  textEval: EvalNodeType = null;
  titleEval: EvalNodeType = null;
  actionConfigEval: EvalNodeType = null;

  private _config : IStepActionButton;

  constructor(index: number, config: IStepActionButton) {
    super(index, config);
    this.title = "";
    this.buttonText = "";

    this._config = config;
  }

  getEvalNodes () : IEvalNodeType[] {
    let evalNodes : IEvalNodeType[] = [];

    if (this._config.hasOwnProperty("valueName")) {
      this.valueName = this._config.valueName;
    } 

    if (this._config.text !== undefined) {
      evalNodes.push(this._config.text);
    }
    if (this._config.title !== undefined) {
      evalNodes.push(this._config.title);
    }
    if (this._config.actions !== undefined) {
      evalNodes.push(this._config.actions);
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      evalNodes.push(this._config.actionConfig); 
    }     
    return evalNodes;

  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {


    if (this._config.text !== undefined) {
      this.textEval = evalNodes.splice(0,1)[0];
    }
    if (this._config.title !== undefined) {
      this.titleEval = evalNodes.splice(0,1)[0];
    }    
    if (this._config.actions !== undefined) {
      this.actionEval = evalNodes.splice(0,1)[0];
    }

    if (this._config.hasOwnProperty("actionConfig")) {
      this.actionConfigEval = evalNodes.splice(0,1)[0];
    } 
    this._config = null;

  }

  initialise (topBranch : BranchData) {
    super.initialise(topBranch);

    this._subscriptions.push (
      topBranch.branchEvent.subscribe(event => {
        this.handleBranchEvent(event);
      })
    ); 
  }

  destroy () {
  }

  private async setActionConfig (evalNode : IEvalNodeType) {

    this.actionConfig = {};

    try {
      let configDataValue = await this.assistEval.evaluate(evalNode);
      if (configDataValue.isValue && configDataValue.isObject) {
        this.actionConfig = configDataValue.getValue();

        if (this.actionConfig.hasOwnProperty("onComplete")) {
          this.enableOnComplete = this.actionConfig.onComplete;
        }

      }
    }
    catch(err) {
      console.log(err);
    }
  }

  deactivate(): boolean {
    let result : boolean;

    result = super.deactivate();

    return result;
    
  }


  async activate(): Promise<boolean> {
    let result: boolean;
    
    if (this.actionConfigEval != null) {
      await this.setActionConfig(this.actionConfigEval);
    }

    result = await super.activate();

    if (this.valueName !== "" && this.dataValue === null) {
      this.dataValue =  this.scope.findDeclareValue({type:EnumValueType.Value_object, name: this.valueName});

      if (this.dataValue) {

        if (this.dataValue.isValue) {
          this.show = this.dataValue.getValue() as boolean;
        }

        this._subscriptions.push (
          this.dataValue.onChangeValue.subscribe((value) => {
             this.show = value as boolean;
          })
        );
      }

    }    

    let textValueData : ValueBase = await this.assistEval.evaluate(this.textEval);    
    if (textValueData.isValue) {
      this.text = textValueData.getValue() as string;
    } else {
      this.text = "Button"
    }

    let titleValueData : ValueBase = await this.assistEval.evaluate(this.titleEval); 
    if (titleValueData.isValue) {
      this.title = titleValueData.getValue() as string;
    }

    this.sendEvent(EnumEventType.stepComplete, { index: this.index });

    return result;
  }  

  handleBranchEvent(event: EventData) {
    let complete = this.complete;
    if (event.type == EnumEventType.branchComplete) {
      complete = true;

    } else if (event.type == EnumEventType.branchIncomplete) {
      complete = false;
    }

    if (complete !== this.complete) {
      this.complete = complete;
    }
  }

  async processAction(): Promise<void> {
 
    if (await this.assistStateService.checkSession()) {
  
      this.isPending = true;
      this.processFailure = false;
  
      let dataValue = await this.assistEval.evaluate(this.actionEval, []);
  
      if (dataValue.isValue) {
        this.doneProcess = true;
        this.processFailure = !(dataValue.getValue() as boolean);
      } else {
        this.processFailure = dataValue.isError;
      }
      
      this.isPending = false;
  
      this.sendEvent(EnumEventType.actionCompleted, this);
    }
  }   

  get canProcess() : boolean {
    return (!this.enableOnComplete || this.complete) && this.show;
  }

  get isOutstanding() : boolean {
    return this.show && (this.processFailure || !this.doneProcess);
  }

}

