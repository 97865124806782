<ng-container *ngIf="show">
<div class="assistStep">
  <div class="stepOffset">
  </div>
  <div class="stepText">
    <p>{{stepData.processText}}</p>
  </div>
  <div class="stepButtons">
    <button *ngIf="showSpinner" mat-button color="basic" class="spinner" style="margin:10px 10px 0 0;" ></button>
  </div>
</div> 
</ng-container>