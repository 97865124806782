import { EnumStepType } from '../enums/enum-step-type';
import { IAssistConfig } from '../interfaces/iassist-config';
import { AssistValues } from './assist-values';
import { BranchData } from './step-branch';
import { StepFactory } from './step-factory';
import { IBranchData } from '../interfaces/ibranch-data';
import { IStepEval } from '../interfaces/istep-eval';
import { StepEvalBranch } from './step-eval-branch';
import { EvalNode } from './eval-node';
import { IEvalNode } from '../interfaces/ieval-node';
import { IEvalNodeType } from '../types/eval-type';
import { StepBase } from './step-base';
import { Scope } from './scope';


export class AssistConfig {

  private static instance : AssistConfig;
  private defaultName : string = "";
  appConfig: object;
  branch : BranchData = null;
  assistValues: AssistValues;

  private constructor() {};

  public static getInstance(): AssistConfig {
    if (!AssistConfig.instance) {
      AssistConfig.instance = new AssistConfig();
    }  

    return AssistConfig.instance;
  }  

  initialise (config : IAssistConfig) {

    if (config.hasOwnProperty("appConfig")) {
      this.appConfig = config.appConfig;
    }

    this.assistValues = AssistValues.getInstance()
    this.assistValues.initialise(config.params);
    
    if (config.hasOwnProperty("branch")) {

      this.defaultName = "root";
        
      this.branch = this.createBranch(0, null, config.branch, null);
      
      this.branch.initialise(this.branch);
    }

  };

  getDefaultName() {
    return this.defaultName;
  }

  createBranch(branchIndex: number, parentScope: Scope, config : IBranchData, parentStep: StepBase) : BranchData {

    let newBranch = new BranchData(parentScope, config);

    newBranch.setParent(parentStep);
    let scope : Scope = newBranch.scope;

    if (config.hasOwnProperty("steps")) {

      config.steps.forEach((step, index) => {

        let saveName: string = this.defaultName;
        
        this.defaultName = saveName + "_" + branchIndex + "_" + index;

        let stepData = StepFactory.createStep(index, step, this.defaultName);
        newBranch.addStep(stepData);
        stepData.setParentStep(parentStep);
        stepData.setScope(scope);

        if (step.type == EnumStepType.Eval_Branch ||
          step.type == EnumStepType.Eval_While ||
          step.type == EnumStepType.Eval_Group ||
          step.type == EnumStepType.Eval_Set) {

          let stepEval : IStepEval = step as IStepEval;
  
          if (stepEval.hasOwnProperty("branches")) {
  
            stepEval.branches.forEach((branch, index) => {
              
              let branchData = this.createBranch(index, newBranch.scope, branch, stepData);
              (stepData as StepEvalBranch).addBranch(branchData);

            });
          }
        }

        let configEvalNodes : IEvalNodeType[] = stepData.getEvalNodes();
        let stepEvalNodes : IEvalNodeType[] = [];

        configEvalNodes.forEach((evalNode: IEvalNodeType) => {
          stepEvalNodes.push(this.createEvalNode(scope, evalNode, stepData));
        });

        stepData.setEvalNodes(stepEvalNodes);

        this.defaultName = saveName;   

      });
    }

    return newBranch;
  }

  createEvalNode(scope: Scope, evalNode : IEvalNodeType, parent : StepBase) : IEvalNodeType {

    let newNode : IEvalNodeType;

    if (typeof evalNode === "object" && EvalNode.isEvalNode(evalNode)) {

      newNode = new EvalNode(scope, evalNode as EvalNode);
      
      if (evalNode.hasOwnProperty("params")) {

        (evalNode as IEvalNode).params.forEach(param => {
  
          let paramNode : IEvalNodeType;
  
          if (typeof param === "object" && EvalNode.isEvalNode(param)) {
            paramNode = this.createEvalNode(scope, param as IEvalNode, parent);          
          } else {
            if (typeof param == "string") {
              paramNode = this.assistValues.unescapeQuote(param);
            } else {
              paramNode = param;
            }
          }
  
          (newNode as EvalNode).addParamNode(paramNode);
  
        });
  
      }

      if (evalNode.hasOwnProperty("branch")) {       ;
        (newNode as EvalNode).branch = this.createBranch(0, scope, (evalNode as IEvalNode).branch as IBranchData, parent);
      }      
  
    } else {
      newNode = evalNode;
    }

    return newNode

  }

  startWorkflow() {

    if (this.branch) {
      this.branch.activateNext();
    }

  }

  restart() {
    this.reset();
  }

  reset() {
    this.destroy();
  }

  destroy () {
    this.assistValues.destroy();
    if (this.branch) {
      this.branch.destroy();
    }
  }

}
