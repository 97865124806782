<ng-container *ngIf="stepData.active" >
  <div #scrollTarget class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div class="stepButtons">
    </div>
  </div>
  <ng-container *ngIf="!stepData.stepCompleted" >
    <div class="assistStep">
      <div class="stepOffset">
      </div>
      <div class="stepFlags">
        <div class="stepFlag" *ngFor="let entry of stepData.arrEntryList;index as idx">
          <mat-button-toggle-group [(ngModel)]="selected[idx]" name="Select" aria-label="Select entry">
            <mat-button-toggle value="{{stepData.arrEntryValue[idx]}}" class="mat-button" (click)="select(idx)">Set</mat-button-toggle>
          </mat-button-toggle-group> 
          <div class="stepFlagWrap">
            <img src="/assets/images/noImage.png" class="imgMcFlag" id="mc_{{entry.flag}}"/>
            <span>{{entry.text}}</span>
          </div>
        </div>
      </div>
    </div> 
    <div class="assistStep">
      <div class="stepOffset">
      </div>
      <div class="stepText stepData">
      </div>
      <div class="stepButtons">
        <div class="stepButtonEnd">
          <mat-button-toggle-group *ngIf="!stepData.stepCompleted" name="Next" aria-label="Next">
            <mat-button-toggle class="mat-button" (click)="process()">Next</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="stepData.stepCompleted" >  
    <div class="assistStep">
      <div class="stepOffset">
      </div>
      <div class="stepText stepData">
        <p>{{strSelected}}</p>
      </div>
      <div class="stepButtons">
        <div class="stepButtonEnd">
          <mat-button-toggle-group  *ngIf="stepData.stepCompleted" name="Update" aria-label="Update selection">
            <mat-button-toggle class="mat-button" (click)="update()">Update</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>