<h3 *ngIf="layoutOptions.title">{{layoutOptions.title}}</h3>
<!-- <ng-container *ngIf="actionType!='statement'"><div #carinaHost id="carina-main"></div></ng-container>
<ng-container *ngIf="actionType=='statement'">
  <div class="actionWrapper">
    <div>
      <div #carinaHost id="carina-status"></div>
    </div>
    <div class="actionMain">
      <div class="actionStatement"><div #carinaHost id="carina-main"></div></div>
      <div class="actionSub" ><div #carinaHost id="carina-buttons"></div><span>actionButtons</span></div>
    </div>
  </div>
</ng-container> -->
<div>

  <carina-layout-host [layout]="layout"></carina-layout-host>


  <!-- <button mat-raised-button color="primary" style="margin:10px 10px 0 0;" (click)="reset()">Reset</button>  -->
  <!-- <button mat-raised-button color="primary" style="margin:10px 10px 0 0;" (click)="restart()">Restart</button> -->
  <!-- <button *ngIf="complete" mat-raised-button color="primary" style="margin:10px 10px 0 0;">Save</button>
  <button *ngIf="complete" mat-raised-button color="primary" style="margin:10px 10px 0 0;">Complete Actions</button> -->
</div>

<div *ngIf="waitText.length" class="logText">{{waitText}}</div>


<div *ngIf="consoleText.length" class="consoleLog">{{consoleText}}</div>
