import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

//import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';

import { AssistComponent } from './components/assist.component';
import { AssistDataViewComponent } from './components/views/assist-value-view.component';
import { AssistActionViewComponent } from './components/views/assist-action-view.component';
import { AssistStepViewComponent } from './components/views/assist-step-view.component';
import { StepHostDirective } from './directives/step-host.directive';
import { ActionItemHostDirective } from './directives/action-item-host.directive';
import { ValueHostDirective } from './directives/value-host.directive';
import { PromptYNComponent } from './components/prompts/prompt-yn.component';
import { PromptNextComponent } from './components/prompts/prompt-next.component';
import { PromptEntryComponent } from './components/prompts/prompt-entry.component';
import { PromptMultiComponent } from './components/prompts/prompt-multi.component';
import { PromptButtonGroupComponent } from './components/prompts/prompt-buttonGroup.component';
import { PromptButtonComponent } from './components/prompts/prompt-button.component';
import { PromptSelectComponent } from './components/prompts/prompt-select.component';
import { PromptFlagGroupComponent } from './components/prompts/prompt-flagGroup.component';
import { PromptDateRangeComponent } from './components/prompts/prompt-dateRange.component';
import { StepSectionComponent } from './components/steps/step-section.component';
import { StepTextComponent } from './components/steps/step-text.component';
import { MaterialModule } from '../material/material.module';
import { ActionEntryComponent } from './components/actions/action-entry.component';
import { ActionElementComponent } from './components/actions/action-element.component';
import { ValueBooleanComponent } from './components/values/value-boolean.component';
import { ValueNumberComponent } from './components/values/value-number.component';
import { ValueStringComponent } from './components/values/value-string.component';
import { ActionItemComponent } from './components/actions/action-item.component';
import { ActionStatementComponent } from './components/actions/action-statement.component';
import { ActionStatusComponent } from './components/actions/action-status.component'
import { ActionButtonComponent } from './components/actions/action-button.component'
import { StepEvalComponent } from './components/steps/step-eval.component';
import { ValueTableComponent } from './components/values/value-table.component';
import { StepBaseComponent } from './components/steps/step-base.component';
import { ValueBaseComponent } from './components/values/value-base.component';
import { setAssistInjector } from "./services/assist-injector"
import { CarinaMsgBox } from './components/dialogs/dialog-msg-box';
import { ValuePanelComponent } from './components/values/value-panel.component';
import { ValueChipComponent } from './components/values/value-chip.component';
import { PipesModule } from '../pipes/pipes.module'; 
import { LayoutHostComponent } from './components/layout/layout-host'
import { LayoutElementComponent } from './components/layout/layout-element'
import { LayoutDirective } from './directives/layout-element.directive'
import { CarinaDataPickerComponent } from './components/controls/carina-datepicker.component'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AssistComponent, 
    AssistDataViewComponent,
    AssistActionViewComponent,
    AssistStepViewComponent,
    StepHostDirective,
    ActionItemHostDirective,
    ValueHostDirective,
    PromptYNComponent,
    PromptNextComponent,
    PromptEntryComponent,
    PromptMultiComponent,
    PromptButtonComponent,
    PromptSelectComponent,
    PromptButtonGroupComponent,
    PromptFlagGroupComponent,
    PromptDateRangeComponent,
    StepSectionComponent,
    StepTextComponent,
    ActionEntryComponent,
    ActionElementComponent,
    ValueBooleanComponent,
    ValueNumberComponent,
    ValueStringComponent,
    ActionItemComponent,
    ActionStatementComponent,
    ActionStatusComponent,
    ActionButtonComponent,
    StepEvalComponent,
    ValueTableComponent,
    StepBaseComponent,
    ValueBaseComponent,
    CarinaMsgBox,
    ValuePanelComponent,
    ValueChipComponent,
    LayoutHostComponent,
    LayoutElementComponent,
    LayoutDirective,
    CarinaDataPickerComponent

  ],
  exports: [
    AssistComponent  
  ],
  imports: [
    PipesModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule
  ],
  providers: [ 
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } } 
  ],  
  // entryComponents: [
  //   MatButtonToggle, MatButtonToggleGroup
  // ],
  // bootstrap: [MatButtonToggle, MatButtonToggleGroup]
})
export class AssistModule {
  constructor(injector: Injector) {
    setAssistInjector(injector);
  }  
}