import { Observable, Subject, Subscription } from 'rxjs';
import { EnumStepType } from '../enums/enum-step-type';
import { EnumEventType } from '../enums/enum-event-type';
import { IBranchData } from '../interfaces/ibranch-data';
import { EventData } from './event-data';
import { StepBase } from "./step-base";
import { ValueBase } from './value-base';
import { EnumValueType } from '../enums/enum-value-type';
import { ValueFactory } from './value-factory';
import { AssistInjector } from '../services/assist-injector';
import { AssistStateService } from "../services/assist-state.service";
import { IArgDef } from '../interfaces/iarg-def';
import { AssistValues } from './assist-values';
import { Scope } from './scope';

export class BranchData {

  public steps: StepBase[];
  public parent: StepBase = null;
  public type: EnumStepType;
  public scope : Scope;
  
  private _showIndex: number = 0;
  private _complete: number = 0;
  private _required: number = 0;
  private _stepsComplete : boolean[] = [];

  private _callIndex: number = 0;

  private _branchEvent: Subject<EventData> = new Subject();
  public readonly branchEvent: Observable<EventData> = this._branchEvent.asObservable();

  protected _returnedValue: Subject<ValueBase> = new Subject();
  public readonly onValueReturned: Observable<ValueBase> = this._returnedValue.asObservable();  

  private _subscriptions: Subscription [] = [];

  private assistStateService: AssistStateService;
  private assistValues: AssistValues;

  constructor(
    parentScope: Scope,
    branch: IBranchData
  ) {

    this.steps = [];
    this.scope = new Scope(parentScope);

    this._required = branch.steps.length;
    this._stepsComplete.fill(false);
    this.assistStateService = AssistInjector.get(AssistStateService);
    this.assistValues = AssistValues.getInstance();
  }

  addStep(stepData : StepBase) : void {

    this.steps.push(stepData);

    try {
      this._subscriptions.push (
        stepData.stepEvent.subscribe((event) => {
          this.handleStepEvent(event);
        })
      );
    }
    catch (err) {
      console.log("step error");
    }

  }

  setParent(stepDate : StepBase) {
    this.parent = stepDate; 
  }

  destroy () {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this.steps.forEach(step => step.destroy());
  }

  initialise(topBranch: BranchData) {
    this.steps.forEach(step => {
      step.initialise(topBranch);
    })    
  }

  deactivateAtIndex(index: number): boolean {
    let result: boolean = false;

    if (index < this.steps.length) {

      let step: StepBase = this.steps[index];
      result = step.deactivate();

    }

    return result;
  }

  activateNext(): boolean {

    let result: boolean = false;

    if (!this.assistStateService.isUserCancelled) {

      if (this._showIndex < this.steps.length) {

        let step : StepBase = this.steps[this._showIndex];
        this._showIndex++;

        step.activate();

      }

      if (this.steps.length == 0) {
        this._branchEvent.next(new EventData(EnumEventType.branchComplete));
      }
    }

    return result;
  }

  activateGroup() {

    this._showIndex = 0;

    this.steps.forEach((step) => {

      step.activate();

    });

  }

  deactivateBranch() {

    this._showIndex = 0;

    this.steps.forEach((step) => {

      step.deactivate();

    });

  }

  checkCompleteness() {

    let complete = this._stepsComplete.reduce((count, isComplete) => count + (isComplete ? 1 : 0), 0);

    if (complete == this._required) {
 
      this._branchEvent.next(new EventData(EnumEventType.branchComplete));
    } else if (complete < this._required) {
                
      this._branchEvent.next(new EventData(EnumEventType.branchIncomplete));
    }
    this._complete = complete;     
  }

  handleStepEvent(event: EventData) {
    
    let index = event.data.index;
    switch (event.type) {
      case EnumEventType.stepComplete:
        this._stepsComplete[index] = true;

        this.checkCompleteness();

        if (index == this._showIndex - 1) {
          this.activateNext();
        }
      break;
      case EnumEventType.stepIncomplete:
        this._stepsComplete[index] = false;

        this.checkCompleteness();
      break;     
      case EnumEventType.stepCallComplete:
        this._callIndex = index + 1;

        if (event.data.returnDataValue) {
          this._returnedValue.next(event.data.returnDataValue);
        } else {
          this.callBranchNext();
        }

      break;     
    }
  }

  callBranch (fname: string = "", argDefs: IArgDef[] = [], argValues : any[] = []) : Promise<ValueBase> {

    return new Promise<ValueBase>((resolve, reject) => {

      let subscription = this.onValueReturned.subscribe(dataValue => {

        subscription.unsubscribe();

        resolve(dataValue);
      })

      this._callIndex = 0;
      this.scope.callBegin(argDefs, argValues);

      this.callBranchNext();

    });

  }

  callBranchNext() {

    let step : StepBase = null;

    try {

      if (!this.assistStateService.isUserCancelled) {

        if (this._callIndex < this.steps.length) {
          step = this.steps[this._callIndex];
          step.callStep()

        } else {

        
          if (this.steps.length > 0) {
            step = this.steps[this.steps.length - 1];
          }

          this.scope.callEnd();

          this._returnedValue.next(ValueFactory.create({name:"retVal", type: EnumValueType.Value_boolean}));

        }
      }
    } 
    catch (err) {
      this.scope.callEnd();
      throw(err);
    }
  }

}
